// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Breadcrumb, SimpleCard } from "app/components";
// import SimpleMenu from './Simplemenu';
// import {
//   Box,
//   Button,
//   Table,
//   styled,
//   TableRow,
//   TableBody,
//   TableCell,
//   TableHead,
//   TablePagination,
//   IconButton,
//   Icon,
// } from "@mui/material";

// // STYLED COMPONENT
// const StyledTable = styled(Table)(() => ({
//   whiteSpace: "pre",
//   "& thead": {
//     "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
//   },
//   "& tbody": {
//     "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
//   },
// }));

// const ClassroomRow = styled(Box)(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   marginTop: theme.spacing(2), // Space above the row
// }));

// function ClassroomFirstPage() {
//   const navigate = useNavigate();
//   const [classrooms, setClassrooms] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   // Fetch classroom data from API
//   const fetchClassrooms = async () => {
//     try {
//       const response = await fetch('https://attendance.api.lissomtech.in/api/classrooms'); // Your API endpoint
//       if (!response.ok) {
//         throw new Error('Failed to fetch classrooms');
//       }
//       const data = await response.json();
//       setClassrooms(data);
//     } catch (error) {
//       console.error('Error fetching classrooms:', error);
//     }
//   };

//   // Fetch the classroom details by ID
//   const fetchClassroomById = async (id) => {
//     try {
//       const response = await fetch(`https://attendance.api.lissomtech.in/api/classrooms/${id}`);
//       if (!response.ok) {
//         console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
//         throw new Error('Failed to fetch classroom');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching classroom by ID:', error);
//     }
//   };

//   useEffect(() => {
//     fetchClassrooms(); // Fetch classrooms when the component mounts
//   }, []);

//   const handleCreateClick = () => {
//     navigate('/classroom/Createclassroom');
//   };

//   const handleChangePage = (_, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const handleEditClick = async (classroom) => {
//     const fetchedClassroom = await fetchClassroomById(classroom.ID); // Fetch the classroom by ID
//     if (fetchedClassroom) {
//       navigate('/classroom/Createclassroom', { state: { classroom: fetchedClassroom } });
//     }
//   };

//   // Delete classroom by ID
//   const handleDeleteClick = async (id) => {
//     const confirmDelete = window.confirm('Are you sure you want to delete this classroom?');
//     if (!confirmDelete) return;

//     try {
//       const response = await fetch(`https://attendance.api.lissomtech.in/api/classrooms/delete/${id}`, {
//         method: 'DELETE',
//       });

//       if (response.ok) {
//         setClassrooms(classrooms.filter(classroom => classroom.ID !== id)); // Update the UI
//         console.log('Classroom deleted successfully');
//       } else {
//         console.error('Failed to delete classroom');
//       }
//     } catch (error) {
//       console.error('Error deleting classroom:', error);
//     }
//   };

//   return (
//     <Box margin="30px">
//       <Box className="breadcrumb">
//         <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Classroom" }]} />
//       </Box>
//       <Box>
//         // <h2>Classroom Page</h2>
//       </Box>

//       <ClassroomRow>
//         <SimpleMenu />
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleCreateClick}
//           sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
//         >
//           <Icon sx={{ marginRight: 1 }}>add</Icon>
//           Create Classroom
//         </Button>
//       </ClassroomRow>
//       <br />

//       <SimpleCard title="Classroom List">
//         <StyledTable>
//           <TableHead>
//             <TableRow>
//               <TableCell align="left">Classroom Name</TableCell>
//               <TableCell align="center">Class Capacity</TableCell> {/* Updated here */}
//               <TableCell align="center">Emp ID</TableCell> {/* New column */}
//               <TableCell align="center">Designation</TableCell> {/* New column */}
//               <TableCell align="center">Status</TableCell>
//               <TableCell align="center">Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {classrooms
//               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map((classroom) => (
//                 <TableRow key={classroom.ID}> {/* Assuming 'ID' is a unique identifier */}
//                   <TableCell align="left">{classroom.ClassRoom}</TableCell>
//                   <TableCell align="center">{classroom.ClassCapacity}</TableCell> {/* Display Class Capacity */}
//                   <TableCell align="center">{classroom.EmpID}</TableCell> {/* Display Emp ID */}
//                   <TableCell align="center">{classroom.Designation}</TableCell> {/* Display Designation */}
//                   <TableCell align="center">{classroom.Status ? 'Active' : 'Inactive'}</TableCell>
                 
//                   <TableCell align="center">
//                     <IconButton color="primary" onClick={() => handleEditClick(classroom)}>
//                       <Icon>edit</Icon>
//                     </IconButton>
//                     <IconButton color="secondary" onClick={() => handleDeleteClick(classroom.ID)}>
//                       <Icon>delete</Icon>
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//           </TableBody>
//         </StyledTable>

//         {/* Pagination */}
//         <TablePagination
//           sx={{ px: 2 }}
//           component="div"
//           page={page}
//           rowsPerPage={rowsPerPage}
//           count={classrooms.length}
//           onPageChange={handleChangePage}
//           rowsPerPageOptions={[5, 10, 25]}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           nextIconButtonProps={{ "aria-label": "Next Page" }}
//           backIconButtonProps={{ "aria-label": "Previous Page" }}
//         />
//       </SimpleCard>
//     </Box>
//   );
// }

// export default ClassroomFirstPage;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import SimpleMenu from './Simplemenu';
import { LuFileEdit } from "react-icons/lu";
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));
const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));

const ClassroomRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

function ClassroomFirstPage() {
  const navigate = useNavigate();
  const [classrooms, setClassrooms] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [id, setId] = useState(null);
  const [createBy, setCreateBy] = useState(null);
  // Fetch classroom data from API
  const fetchClassrooms = async () => {
    try {
        const storedId = sessionStorage.getItem("ID"); // Assuming this is your session ID
        const response = await fetch(`https://attendance.api.lissomtech.in/api/classrooms?empId=${storedId}`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch classrooms');
        }
        
        const data = await response.json();
        
        // Convert boolean Status to 1 or 0
        const updatedData = data.map(classroom => ({
            ...classroom,
            Status: classroom.Status ? 1 : 0,
        }));
        
        console.log('Fetched classrooms:', updatedData); // Log fetched data for debugging
        setClassrooms(updatedData);
    } catch (error) {
        console.error('Error fetching classrooms:', error);
    }
};


  useEffect(() => {
    // Retrieve ID from session storage and set it to state
    const storedId = sessionStorage.getItem("ID");
    if (storedId) {
        setId(storedId); // Update state with the ID
    }

    // Retrieve Createby from session storage and set it to state
    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
        setCreateBy(storedCreateBy); // Update state with Createby
    }
}, []);

  // Fetch the classroom details by ID
  const fetchClassroomById = async (id) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/classrooms/${id}`);
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
        throw new Error('Failed to fetch classroom');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching classroom by ID:', error);
    }
  };

  useEffect(() => {
    fetchClassrooms(); // Fetch classrooms when the component mounts
  }, []);

  const handleCreateClick = () => {
    navigate('/classroom/Createclassroom');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = async (classroom) => {
    const fetchedClassroom = await fetchClassroomById(classroom.ID); // Fetch the classroom by ID
    if (fetchedClassroom) {
      navigate('/classroom/Createclassroom', { state: { classroom: fetchedClassroom } });
    }
  };

  // Handle status toggle
  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0
    console.log('Toggling status for ID:', id, 'New Status:', newStatus); // Debugging log

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/classrooms/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }), // Update the status
      });

      if (response.ok) {
        console.log('Update successful for classroom ID:', id, 'New Status:', newStatus);
        setClassrooms((prevClassrooms) =>
          prevClassrooms.map((classroom) =>
            classroom.ID === id ? { ...classroom, Status: newStatus } : classroom
          )
        );
      } else {
        console.error('Failed to update classroom status');
      }
    } catch (error) {
      console.error('Error updating classroom status:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Classroom" }]} />
      </Box>
      {/* <Box>
        // <h2>Classroom Page</h2>
      </Box> */}

      <ClassroomRow>
        <SimpleMenu />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Classroom
        </Button>
      </ClassroomRow>
      <br />

      <SimpleCard title="Classroom List">
      <ScrollableTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">Classroom Name</TableCell>
              <TableCell align="center">Class Capacity</TableCell>
              <TableCell align="center">Status</TableCell>
              {/* <TableCell align="center">Description</TableCell> */}
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classrooms
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((classroom) => (
                <TableRow key={classroom.ID}>
                  <TableCell align="left">{classroom.ClassRoom}</TableCell>
                  <TableCell align="center">{classroom.ClassCapacity}</TableCell>
                  <TableCell align="center">
                    {classroom.Status === 1 ? (
                      <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon
                    ) : (
                      <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon
                    )}
                  </TableCell>
                  {/* <TableCell align="center">{classroom.Description}</TableCell> */}
                  <TableCell align="center">{new Date(classroom.CreateDate).toLocaleDateString()}</TableCell>
                  <TableCell align="center">
                
                      <IconButton color="primary" onClick={() => handleEditClick(classroom)}>
                      <LuFileEdit style={{ fontSize: '30px' }} />
                      </IconButton>
                      <Switch
                        checked={classroom.Status === 1} // Active if Status is 1
                        onChange={() => handleStatusChange(classroom.ID, classroom.Status)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                   
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
</ScrollableTableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={classrooms.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default ClassroomFirstPage;
