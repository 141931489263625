import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, Icon, styled, Select, MenuItem } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Breadcrumb, SimpleCard } from "app/components";
import { useLocation, useNavigate } from 'react-router-dom';

// Styled components
const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const CreatePhase = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const phaseToEdit = location.state?.phase; // Get the phase to edit from state
  const [id, setId] = useState(null);
  const [createBy, setCreateBy] = useState(null);
  const [state, setState] = useState({
    phaseName: phaseToEdit ? phaseToEdit.PhaseName : '',
    status: phaseToEdit ? phaseToEdit.Status : false,
 
  });

  useEffect(() => {
    // Retrieve ID from session storage and set it to state
    const storedId = sessionStorage.getItem("ID");
    if (storedId) {
        setId(storedId); // Update state with the ID
    }

    // Retrieve Createby from session storage and set it to state
    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
        setCreateBy(storedCreateBy); // Update state with Createby
    }
}, []);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const currentDateTime = new Date().toISOString();

    // Build the new phase object
    const newPhase = {
      phaseName: state.phaseName,
      status: state.status,
      empId: id, // Use the session ID as EmpID
      designation: sessionStorage.getItem("Createby"),
      createDate: phaseToEdit ? phaseToEdit.CreateDate : currentDateTime, // Use existing create date if editing
      updateDate: currentDateTime, // Set the current date for UpdateDate
    };

    try {
      let response;
      if (phaseToEdit) {
        // Update existing phase (PUT request)
        response = await fetch(`https://attendance.api.lissomtech.in/api/phases/${phaseToEdit.ID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newPhase),
        });
      } else {
        // Create new phase (POST request)
        response = await fetch('https://attendance.api.lissomtech.in/api/phases', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newPhase),
        });
      }

      if (response.ok) {
        console.log('Phase saved successfully');
        navigate('/phasepage'); // Redirect after successful submission
      } else {
        console.error('Failed to save phase');
      }
    } catch (error) {
      console.error('Error saving phase:', error);
    }
  };

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target; // Destructure name and value from target
    setState({ ...state, [name]: value }); // Update the specific state property
  };

  const { phaseName, status, empId, designation } = state;

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Phase", path: "/phase" }, { name: phaseToEdit ? "Edit Phase" : "Create Phase" }]} />
      </Box>
      
      <SimpleCard title={phaseToEdit ? "Edit Phase" : "Create Phase"}>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="phaseName"
              value={phaseName || ""}
              onChange={handleChange}
              errorMessages={["this field is required"]}
              label="Phase Name"
              validators={["required"]}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={status}
                  onChange={(event) => setState({ ...state, status: event.target.checked })}
                />
              }
              label="Status (Active/Inactive)"
            />

          
          </Grid>
        </Grid>

        <Button color="primary" variant="contained" type="submit">
          <Icon>send</Icon>
          <div sx={{ pl: 1, textTransform: "capitalize" }}>{phaseToEdit ? "Update Phase" : "Create Phase"}</div>
        </Button>
      </ValidatorForm>
      </SimpleCard>
    </Box>
  );
};

export default CreatePhase;
