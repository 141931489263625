import React from 'react';
import { Breadcrumb } from "app/components";
import { Box, styled } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './css/Student.css';  // Ensure the updated CSS file is imported

const SettingsPage = () => {
  const navigate = useNavigate();

  // STYLED COMPONENTS
  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
    }
  }));

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Settings", path: "/settings" }, { name: "Settings Details" }]} />
      </Box>

      <div className="settings-container">
        <h3 className="header-title">Setting Details</h3>
        <div className="settings-card-container">
        <motion.div
            className="settings-phase-card"
            onClick={() => handleNavigation('/Phase')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
             <h2>Degree/Year</h2>
            <p>Set up department phases</p>
          </motion.div>

          <motion.div
            className="settings-department-card"
            onClick={() => handleNavigation('/department/department')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            <h2>Department</h2>
            <p>Configure department details</p>
          </motion.div>

        

          <motion.div
            className="settings-subject-card"
            onClick={() => handleNavigation('/Subject')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            <h2>Subject</h2>
            <p>Manage department subjects</p>
          </motion.div>

          <motion.div
            className="settings-prefix-card"
            onClick={() => handleNavigation('/prefix')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
             <h2>Prefix</h2>
            <p>Configure Name prefixes</p>
          </motion.div>
        </div>
      </div>
    </Container>
  );
};

export default SettingsPage;
