import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LuFileEdit } from "react-icons/lu";
// import SimpleMenu from '../Phase/component/Simplemenu'; // Assuming SimpleMenu is shared
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));


const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));

const PhaseRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

function PhaseFirstPage() {
  const navigate = useNavigate();
  const [phases, setPhases] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [empID, setEmpID] = useState(null);

  
  const fetchPhases = async (empID) => {
    if (!empID) {
      console.error('EmpID is not set in session storage.');
      return;
    }

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/phases/${empID}`); // API endpoint to fetch phases by EmpID
      if (!response.ok) {
        throw new Error('Failed to fetch phases');
      }
      const data = await response.json();
      console.log('Fetched phases:', data); // Log fetched data for debugging
      setPhases(data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching phases:', error);
      setErrorMessage('Error fetching phases');
    }
  };

  useEffect(() => {
    const storedEmpID = sessionStorage.getItem('ID'); // Retrieve EmpID from session storage
    if (storedEmpID) {
      setEmpID(storedEmpID);
      fetchPhases(storedEmpID); // Fetch phases using EmpID
    }
  }, []); // Only run on component mount


  // Fetch the phase details by ID
  const fetchPhaseById = async (id) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/phases/${id}`);
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
        throw new Error('Failed to fetch phase');
      }
      const data = await response.json();
      
      // Convert boolean Status to 1 or 0 if needed
      const updatedData = {
        ...data,
        Status: data.Status ? 1 : 0, // Assuming Status is boolean in the database
      };

      console.log('Fetched phase by ID:', updatedData); // Log fetched data for debugging
      return updatedData;
    } catch (error) {
      console.error('Error fetching phase by ID:', error);
      setErrorMessage('Error fetching phase');
    }
  };

  useEffect(() => {
    fetchPhases(); // Fetch phases when the component mounts
  }, []);

  const handleCreateClick = () => {
    navigate('/createPhase'); // Navigate to phase creation page
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = async (phase) => {
    const fetchedPhase = await fetchPhaseById(phase.ID); // Fetch the phase by ID
    if (fetchedPhase) {
      navigate('/createPhase', { state: { phase: fetchedPhase } }); // Pass the phase data for editing
    }
  };

  // Delete phase by ID
  const handleDeleteClick = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this phase?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/phases/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setPhases(phases.filter(phase => phase.ID !== id)); // Update the UI
        console.log('Phase deleted successfully');
      } else {
        console.error('Failed to delete phase');
      }
    } catch (error) {
      console.error('Error deleting phase:', error);
    }
  }; 

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/phases/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }), // Update the status
      });

      if (response.ok) {
        setPhases((prevPhases) =>
          prevPhases.map((phase) =>
          phase.ID === id ? { ...phase, Status: newStatus } : phase
          )
        );
      } else {
        console.error('Failed to update phase status');
      }
    } catch (error) {
      console.error('Error updating phase status:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Phase", path: "/createPhase" }, { name: "Phase List" }]} />
      </Box>
      {/* <Box>
        // <h2>Phase Page</h2>
      </Box> */}

      <PhaseRow>
        {/* <SimpleMenu /> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Phase
        </Button>
      </PhaseRow>
      <br />

      <SimpleCard title="Phase List">
      <ScrollableTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">Phase Name</TableCell>
              {/* <TableCell align="center">Designation</TableCell> */}
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created Date</TableCell>
              {/* <TableCell align="center">Employee ID</TableCell> */}
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {phases
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((phase) => (
                <TableRow key={phase.ID}> {/* Assuming 'ID' is a unique identifier */}
                  <TableCell align="left">{phase.PhaseName}</TableCell>
                  {/* <TableCell align="center">{phase.Designation}</TableCell> */}
                  <TableCell align="center">
                  {phase.Status === true || phase.Status === 1 ? (
                      <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon
                    ) : (
                      <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon
                    )}
                  </TableCell>
                  <TableCell align="center">{new Date(phase.CreateDate).toLocaleDateString()}</TableCell>
                  {/* <TableCell align="center">{phase.EmpID}</TableCell> */}
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <IconButton color="primary" onClick={() => handleEditClick(phase)}>
                      <LuFileEdit style={{ fontSize: '30px' }} />
                      </IconButton>
                      {/* <IconButton color="secondary" onClick={() => handleDeleteClick(phase.ID)}>
                        <Icon>delete</Icon>
                      </IconButton> */}
                      <Switch
                        checked={phase.Status === true || phase.Status === 1}
                        onChange={() => handleStatusChange(phase.ID, phase.Status)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
</ScrollableTableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={phases.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default PhaseFirstPage;