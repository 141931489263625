
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Breadcrumb, SimpleCard } from "app/components";
// import {
//   Box,
//   Button,
//   Table,
//   styled,
//   TableRow,
//   TableBody,
//   TableHead,
//   TableCell,
//   Icon,
// } from "@mui/material";
// import axios from 'axios';

// const StyledTable = styled(Table)(() => ({
//   whiteSpace: "pre",
//   "& thead": {
//     "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
//   },
//   "& tbody": {
//     "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
//   },
// }));

// const ScrollableTableContainer = styled(Box)(({ theme }) => ({
//   overflowX: "auto",
//   '& table': {
//     minWidth: 600,
//   },
// }));

// function TimetableFirst() {
//   const [timetableData, setTimetableData] = useState([]);
//   const navigate = useNavigate();

//   const handleCreateClick = () => {
//     navigate('/createtimetable');
//   };


//   useEffect(() => {
//     const fetchTimetableData = async () => {
//       try {
//         const response = await axios.get('https://attendance.api.lissomtech.in/api/timetable');
        
//         // Sort the timetable entries based on StartTime
//         const sortedData = response.data.sort((a, b) => {
//           // Convert StartTime to Date objects for comparison
//           const startA = new Date(a.StartTime);
//           const startB = new Date(b.StartTime);
//           return startA - startB; // Ascending order
//         });
  
//         const transformedData = sortedData.reduce((acc, item) => {
//           const dayWiseEntry = acc.find(entry => entry.session === item.Session) || {
//             session: item.Session,
//             monday: [],
//             tuesday: [],
//             wednesday: [],
//             thursday: [],
//             friday: [],
//             saturday: []
//           };
  
//           // Convert StartTime and EndTime from UTC to IST
//           const utcStartTime = new Date(item.StartTime);
//           const utcEndTime = new Date(item.EndTime);
          
//           // Convert UTC time to IST (UTC+5:30)
//           const istOffset = 5 * 60 + 30; // IST is UTC + 5 hours 30 minutes
//           const startTimeIST = new Date(utcStartTime.getTime() - istOffset * 60 * 1000);
//           const endTimeIST = new Date(utcEndTime.getTime() - istOffset * 60 * 1000);
          
//           // Format the times to AM/PM
//           const startTime = startTimeIST.toLocaleTimeString('en-US', {
//             hour: '2-digit',
//             minute: '2-digit',
//             hour12: true,
//           });
  
//           const endTime = endTimeIST.toLocaleTimeString('en-US', {
//             hour: '2-digit',
//             minute: '2-digit',
//             hour12: true,
//           });
  
//           // Push the subject and professor details to the respective day
//           dayWiseEntry[item.WeekDay.toLowerCase()].push({
//             subject: item.Subject || "N/A",
//             professor: item.Professor || "N/A",
//             time: `${startTime} - ${endTime}`,
//           });
  
//           // If the session doesn't exist, push the dayWiseEntry
//           if (!acc.find(entry => entry.session === item.Session)) {
//             acc.push(dayWiseEntry);
//           }
  
//           return acc;
//         }, []);
  
//         setTimetableData(transformedData);
//       } catch (error) {
//         console.error('Error fetching timetable data:', error);
//       }
//     };
  
//     fetchTimetableData();
//   }, []);
  

//   // useEffect(() => {
//   //   const fetchTimetableData = async () => {
//   //     try {
//   //       const response = await axios.get('https://attendance.api.lissomtech.in/api/timetable');
//   //       const transformedData = response.data.reduce((acc, item) => {
//   //         const dayWiseEntry = acc.find(entry => entry.session === item.Session) || {
//   //           session: item.Session,
//   //           monday: [],
//   //           tuesday: [],
//   //           wednesday: [],
//   //           thursday: [],
//   //           friday: [],
//   //           saturday: []
//   //         };
  
//   //         // Parse StartTime and EndTime from API response (UTC)
//   //         const utcStartTime = new Date(item.StartTime);
//   //         const utcEndTime = new Date(item.EndTime);
  
//   //         // Convert UTC time to IST (UTC+5:30)
//   //         const istOffset = 5 * 60 + 30; // IST is UTC + 5 hours 30 minutes
//   //         const startTimeIST = new Date(utcStartTime.getTime() - istOffset * 60 * 1000);
//   //         const endTimeIST = new Date(utcEndTime.getTime() - istOffset * 60 * 1000);
  
//   //         // Format the times to AM/PM
//   //         const startTime = startTimeIST.toLocaleTimeString('en-US', {
//   //           hour: '2-digit',
//   //           minute: '2-digit',
//   //           hour12: true,
//   //         });
  
//   //         const endTime = endTimeIST.toLocaleTimeString('en-US', {
//   //           hour: '2-digit',
//   //           minute: '2-digit',
//   //           hour12: true,
//   //         });
  
//   //         // Push the subject and professor details to the respective day
//   //         dayWiseEntry[item.WeekDay.toLowerCase()].push({
//   //           subject: item.Subject || "N/A",
//   //           professor: item.Professor || "N/A",
//   //           time: `${startTime} - ${endTime}`,
//   //         });
  
//   //         // If the session doesn't exist, push the dayWiseEntry
//   //         if (!acc.find(entry => entry.session === item.Session)) {
//   //           acc.push(dayWiseEntry);
//   //         }
  
//   //         return acc;
//   //       }, []);
  
//   //       setTimetableData(transformedData);
//   //     } catch (error) {
//   //       console.error('Error fetching timetable data:', error);
//   //     }
//   //   };
  
//   //   fetchTimetableData();
//   // }, []);
  

//   return (
//     <Box margin="30px">
//       <Box className="breadcrumb">
//         <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Timetable" }]} />
//       </Box>

//       <Button
//         variant="contained"
//         color="primary"
//         onClick={handleCreateClick}
//         sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
//       >
//         <Icon sx={{ marginRight: 1 }}>add</Icon>
//         Create Timetable
//       </Button>

//       <br /><br />

//       <SimpleCard title="Timetable List">
//         <ScrollableTableContainer>
//           <StyledTable>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Monday</TableCell>
//                 <TableCell>Tuesday</TableCell>
//                 <TableCell>Wednesday</TableCell>
//                 <TableCell>Thursday</TableCell>
//                 <TableCell>Friday</TableCell>
//                 <TableCell>Saturday</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {timetableData.map((row, index) => {
//                 const maxLength = Math.max(
//                   row.monday.length,
//                   row.tuesday.length,
//                   row.wednesday.length,
//                   row.thursday.length,
//                   row.friday.length,
//                   row.saturday.length
//                 );

//                 return Array.from({ length: maxLength }).map((_, dayIndex) => (
//                   <TableRow key={`${index}-${dayIndex}`}>
//                     <TableCell>
//                       {row.monday[dayIndex] && (
//                         <>
//                           {row.monday[dayIndex].subject}<br />
//                           {row.monday[dayIndex].professor}<br />
//                           {row.monday[dayIndex].time}
//                         </>
//                       )}
//                     </TableCell>
//                     <TableCell>
//                       {row.tuesday[dayIndex] && (
//                         <>
//                           {row.tuesday[dayIndex].subject}<br />
//                           {row.tuesday[dayIndex].professor}<br />
//                           {row.tuesday[dayIndex].time}
//                         </>
//                       )}
//                     </TableCell>
//                     <TableCell>
//                       {row.wednesday[dayIndex] && (
//                         <>
//                           {row.wednesday[dayIndex].subject}<br />
//                           {row.wednesday[dayIndex].professor}<br />
//                           {row.wednesday[dayIndex].time}
//                         </>
//                       )}
//                     </TableCell>
//                     <TableCell>
//                       {row.thursday[dayIndex] && (
//                         <>
//                           {row.thursday[dayIndex].subject}<br />
//                           {row.thursday[dayIndex].professor}<br />
//                           {row.thursday[dayIndex].time}
//                         </>
//                       )}
//                     </TableCell>
//                     <TableCell>
//                       {row.friday[dayIndex] && (
//                         <>
//                           {row.friday[dayIndex].subject}<br />
//                           {row.friday[dayIndex].professor}<br />
//                           {row.friday[dayIndex].time}
//                         </>
//                       )}
//                     </TableCell>
//                     <TableCell>
//                       {row.saturday[dayIndex] && (
//                         <>
//                           {row.saturday[dayIndex].subject}<br />
//                           {row.saturday[dayIndex].professor}<br />
//                           {row.saturday[dayIndex].time}
//                         </>
//                       )}
//                     </TableCell>
//                   </TableRow>
//                 ));
//               })}
//             </TableBody>
//           </StyledTable>
//         </ScrollableTableContainer>
//       </SimpleCard>
//     </Box>
//   );
// }

// export default TimetableFirst;






import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { FaEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Icon,
} from "@mui/material";
import axios from 'axios';

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  '& table': {
    minWidth: 600,
  },
}));

function Timetablefirst() {
  const [timetableData, setTimetableData] = useState([]);
  const navigate = useNavigate();
  const [empID, setEmpID] = useState(null);
  const { timetableId } = useParams();  // Extract timetableId from the URL

  const handleCreateClick = () => {
    navigate('/createtimetable');
  };

  useEffect(() => {
    const fetchTimetableData = async () => {
      try {
       const empID = sessionStorage.getItem('ID'); // Fetch empID from session storage

   
          const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetable/${empID}`);
        

        // Sort the timetable entries based on StartTime
        const sortedData = response.data.sort((a, b) => {
          // Convert StartTime to Date objects for comparison
          const startA = new Date(a.StartTime);
          const startB = new Date(b.StartTime);
          return startA - startB; // Ascending order
        });
  
        const transformedData = sortedData.reduce((acc, item) => {
          const dayWiseEntry = acc.find(entry => entry.session === item.Session) || {
            session: item.Session,
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: []
          };
  
          // Convert StartTime and EndTime from UTC to IST
          const utcStartTime = new Date(item.StartTime);
          const utcEndTime = new Date(item.EndTime);
          
          // Convert UTC time to IST (UTC+5:30)
          const istOffset = 5 * 60 + 30; // IST is UTC + 5 hours 30 minutes
          const startTimeIST = new Date(utcStartTime.getTime() - istOffset * 60 * 1000);
          const endTimeIST = new Date(utcEndTime.getTime() - istOffset * 60 * 1000);
          
          // Format the times to AM/PM
          const startTime = startTimeIST.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
  
          const endTime = endTimeIST.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
  
          // Push the subject and professor details to the respective day
          dayWiseEntry[item.WeekDay.toLowerCase()].push({
            subject: item.Subject || "N/A",
            professor: item.Professor || "N/A",
            time: `${startTime} - ${endTime}`,
            ID: item.ID
          });
  
          // If the session doesn't exist, push the dayWiseEntry
          if (!acc.find(entry => entry.session === item.Session)) {
            acc.push(dayWiseEntry);
          }
  
          return acc;
        }, []);
      
        setTimetableData(transformedData);

      } catch (error) {
        console.error('Error fetching timetable data:', error);
      }
    };
  
    fetchTimetableData();
  }, []);

  useEffect(() => {
    const storedId = sessionStorage.getItem('ID');
    if (storedId) {
      fetchTimetableEntryById(storedId);
    } else {
      console.error("No ID found in session storage");
    }
  }, []);
  

  const fetchTimetableEntryById = async (timetableId) => {
    if (!timetableId) {
      console.error("Timetable ID is missing or invalid");
      return;
    }
  
    try {
      const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetablee/${timetableId}`);
      if (response.data) {
        console.log("Timetable data:", response.data);
      } else {
        console.error(`No timetable data found for ID: ${timetableId}`);
      }
    } catch (error) {
      console.error("Error fetching timetable entry:", error);
    }
  };
  
  // const handleEditClick = (timetableId) => {
  //   if (timetableId) {
  //     fetchTimetableEntryById(timetableId);  // Fetch the timetable data if ID is provided
  //   } else {
  //     console.error("Timetable ID is undefined");
  //     navigate('/createtimetable');  // Navigate to CreateTimetable page when ID is undefined
  //   }
  // };
  
  const handleEditClick = (timetableEntry) => {
    const timetableId = timetableEntry.ID; // Ensure you are extracting the ID correctly
    console.log("Timetable ID:", timetableId); // Check the logged output
    if (timetableId) {
      fetchTimetableEntryById(timetableId);
      navigate(`/createtimetable/${timetableId}`); // Use the correct timetableId
    } else {
      console.error("Timetable ID is undefined");
    }
  };
  
  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Timetable" }]} />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateClick}
        sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
      >
        <Icon sx={{ marginRight: 1 }}>add</Icon>
        Create Timetable
      </Button>

      <br /><br />

      <SimpleCard title="Timetable List">
        <ScrollableTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Monday</TableCell>
                <TableCell>Tuesday</TableCell>
                <TableCell>Wednesday</TableCell>
                <TableCell>Thursday</TableCell>
                <TableCell>Friday</TableCell>
                <TableCell>Saturday</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timetableData.map((row, index) => {
                const maxLength = Math.max(
                  row.monday.length,
                  row.tuesday.length,
                  row.wednesday.length,
                  row.thursday.length,
                  row.friday.length,
                  row.saturday.length
                );

                return Array.from({ length: maxLength }).map((_, dayIndex) => (
                  <TableRow key={`${index}-${dayIndex}`}>
                    <TableCell>
                      {row.monday[dayIndex] ? (
                        <>
                          {row.monday[dayIndex].subject}<br />
                          {row.monday[dayIndex].professor}<br />
                          {row.monday[dayIndex].time}<br/>
                          {/* <Button
  variant="text" 
  color="primary"
  startIcon={<FaEdit />}
  onClick={() => handleEditClick(row.monday[dayIndex]?.ID)} // Pass the timetable ID
  sx={{ marginTop: '8px' }}
>
  Edit
</Button> */}
<Button
  variant="text" 
  color="primary"
  startIcon={<FaEdit />}
  onClick={() => handleEditClick(row.monday[dayIndex])} // Pass the whole entry, not just the ID
  sx={{ marginTop: '8px' }}
>
  Edit
</Button>


                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {row.tuesday[dayIndex] ? (
                        <>
                          {row.tuesday[dayIndex].subject}<br />
                          {row.tuesday[dayIndex].professor}<br />
                          {row.tuesday[dayIndex].time}<br/>
                          <Button
                variant="text" 
                color="primary"
                startIcon={<FaEdit />}
                onClick={() => handleEditClick(row.tuesday[dayIndex])} // Use correct entry for Monday
                sx={{ marginTop: '8px' }}
              >
                Edit
              </Button>
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {row.wednesday[dayIndex] ? (
                        <>
                          {row.wednesday[dayIndex].subject}<br />
                          {row.wednesday[dayIndex].professor}<br />
                          {row.wednesday[dayIndex].time}<br/>
                          <Button
                variant="text" 
                color="primary"
                startIcon={<FaEdit />}
                onClick={() => handleEditClick(row.wednesday[dayIndex])} // Use correct entry for Monday
                sx={{ marginTop: '8px' }}
              >
                Edit
              </Button>
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {row.thursday[dayIndex] ? (
                        <>
                          {row.thursday[dayIndex].subject}<br />
                          {row.thursday[dayIndex].professor}<br />
                          {row.thursday[dayIndex].time}<br/>
                          <Button
                variant="text" 
                color="primary"
                startIcon={<FaEdit />}
                onClick={() => handleEditClick(row.thursday[dayIndex])} // Use correct entry for Monday
                sx={{ marginTop: '8px' }}
              >
                Edit
              </Button>
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {row.friday[dayIndex] ? (
                        <>
                          {row.friday[dayIndex].subject}<br />
                          {row.friday[dayIndex].professor}<br />
                          {row.friday[dayIndex].time}<br/>
                          <Button
                variant="text" 
                color="primary"
                startIcon={<FaEdit />}
                onClick={() => handleEditClick(row.friday[dayIndex])} // Use correct entry for Monday
                sx={{ marginTop: '8px' }}
              >
                Edit
              </Button>
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {row.saturday[dayIndex] ? (
                        <>
                          {row.saturday[dayIndex].subject}<br />
                          {row.saturday[dayIndex].professor}<br />
                          {row.saturday[dayIndex].time}<br/>
                          <Button
                variant="text" 
                color="primary"
                startIcon={<FaEdit />}
                onClick={() => handleEditClick(row.saturday[dayIndex])} // Use correct entry for Monday
                sx={{ marginTop: '8px' }}
              >
                Edit
              </Button>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ));
              })}
            </TableBody>
          </StyledTable>
        </ScrollableTableContainer>
      </SimpleCard>
    </Box>
  );
}

export default Timetablefirst;
