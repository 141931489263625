
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LuFileEdit } from "react-icons/lu";
import SimpleMenu from './Simplemenu'; // Assuming SimpleMenu is shared
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));


const YearRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

function YearFirstPage() {
  const navigate = useNavigate();
  const [years, setYears] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [empID, setEmpID] = useState(null);

  const fetchYears = async (empID) => {
    if (!empID) {
      console.error('EmpID is not set in session storage.');
      return;
    }

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/yearss/${empID}`); // Updated API endpoint
      if (!response.ok) {
        throw new Error('Failed to fetch years');
      }
      const data = await response.json();
      
      // Convert boolean Status to 1 or 0
      const updatedData = data.map(year => ({
        ...year,
        Status: year.Status ? 1 : 0,
      }));
      
      setYears(updatedData);
    } catch (error) {
      console.error('Error fetching years:', error);
    }
  };

  useEffect(() => {
    const storedEmpID = sessionStorage.getItem('ID');
    if (storedEmpID) {
      setEmpID(storedEmpID); // Set the EmpID state
      fetchYears(storedEmpID); // Fetch years using EmpID
    }
  }, []); // Fetch when the component mounts


  // Fetch year details by ID
  const fetchYearById = async (id) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/years/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch year');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching year by ID:', error);
    }
  };

  useEffect(() => {
    fetchYears(); // Fetch years when the component mounts
  }, []);

  const handleCreateClick = () => {
    navigate('/createYear');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = async (year) => {
    const fetchedYear = await fetchYearById(year.ID); // Fetch the year by ID
    if (fetchedYear) {
      navigate('/createYear', { state: { year: fetchedYear } });
    }
  };

  // Handle status toggle for years
  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/years/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }), // Update the status
      });

      if (response.ok) {
        setYears((prevYears) =>
          prevYears.map((year) =>
            year.ID === id ? { ...year, Status: newStatus } : year
          )
        );
      } else {
        console.error('Failed to update year status');
      }
    } catch (error) {
      console.error('Error updating year status:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Year" }]} />
      </Box>
      <Box>
        {/* <h2>Year Page</h2> */}
      </Box>

      <YearRow>
        <SimpleMenu />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Year
        </Button>
      </YearRow>
      <br />

      <SimpleCard title="Year List">
      <ScrollableTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">Year Name</TableCell>
              <TableCell align="center">Description</TableCell>
              {/* <TableCell align="center">Employee ID</TableCell> */}
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {years
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((year) => (
                <TableRow key={year.ID}>
                  <TableCell align="left">{year.YearName}</TableCell>
                  <TableCell align="center">{year.Description}</TableCell>
                  {/* <TableCell align="center">{year.EmpID}</TableCell> */}
                  <TableCell align="center">
                    {year.Status === 1 ? (
                      <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon
                    ) : (
                      <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <IconButton color="primary" onClick={() => handleEditClick(year)}>
                      <LuFileEdit style={{ fontSize: '30px' }} />
                      </IconButton>
                      <Switch
                        checked={year.Status === 1} // Active if Status is 1
                        onChange={() => handleStatusChange(year.ID, year.Status)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
</ScrollableTableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={years.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default YearFirstPage;

