import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, Icon, styled } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Breadcrumb, SimpleCard } from "app/components";
import { useLocation, useNavigate } from 'react-router-dom';

// Styled components
const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

const CreateYear = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const yearToEdit = location.state?.year; // Get the year to edit from state
  const [id, setId] = useState(null);
  const [createBy, setCreateBy] = useState(null);
  const [state, setState] = useState({
    yearName: yearToEdit ? yearToEdit.YearName : '',
    description: yearToEdit ? yearToEdit.Description : '',
    status: yearToEdit ? yearToEdit.Status : false,
    createdDate: yearToEdit ? new Date(yearToEdit.CreatedDate) : new Date(),
    termsAgreed: false,
  });


  useEffect(() => {
    // Retrieve ID from session storage and set it to state
    const storedId = sessionStorage.getItem("ID");
    if (storedId) {
        setId(storedId); // Update state with the ID
    }

    // Retrieve Createby from session storage and set it to state
    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
        setCreateBy(storedCreateBy); // Update state with Createby
    }
}, []);


  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const currentDateTime = new Date().toISOString();

    // Check if createdDate is valid
    const finalCreatedDate = state.createdDate instanceof Date && !isNaN(state.createdDate)
      ? state.createdDate.toISOString()
      : currentDateTime; // Fallback to current time if invalid

    // Build the new year object
    const newYear = {
      yearName: state.yearName,
      description: state.description,
      status: state.status,
      createdDate: yearToEdit ? finalCreatedDate : currentDateTime, // Use validated createdDate
      empId: id, // Use the session ID as EmpID
      designation: sessionStorage.getItem("Createby"),
      updateDate: currentDateTime, // Set the current date for UpdateDate
    };

    try {
      let response;
      if (yearToEdit) {
        // Update existing year (PUT request)
        response = await fetch(`https://attendance.api.lissomtech.in/api/years/${yearToEdit.ID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newYear),
        });
      } else {
        // Create new year (POST request)
        response = await fetch('https://attendance.api.lissomtech.in/api/years', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newYear),
        });
      }

      if (response.ok) {
        console.log('Year saved successfully');
        navigate('/year'); // Redirect after successful submission
      } else {
        console.error('Failed to save year');
      }
    } catch (error) {
      console.error('Error saving year:', error);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, createdDate: date });

  const { yearName, description, status, createdDate, empId, termsAgreed } = state;

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Year", path: "/year" }, { name: yearToEdit ? "Edit Year" : "Create Year" }]} />
      </Box>
  
      <SimpleCard title={yearToEdit ? "Edit Year" : "Create Year"}>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="yearName"
              value={yearName || ""}
              onChange={handleChange}
              errorMessages={["this field is required"]}
              label="Year Name"
              validators={["required"]}
            />

            <TextField
              type="text"
              name="description"
              value={description || ""}
              onChange={handleChange}
              label="Year Description"
              validators={["required"]}
              errorMessages={["this field is required"]}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={status}
                  onChange={(event) => setState({ ...state, status: event.target.checked })}
                />
              }
              label="Status (Active/Inactive)"
            />

          
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={termsAgreed}
                  onChange={(event) => setState({ ...state, termsAgreed: event.target.checked })}
                />
              }
              label="I have read and agree to the terms of service."
            /> */}
          </Grid>
        </Grid>

        <Button color="primary" variant="contained" type="submit">
          <Icon>send</Icon>
          <div sx={{ pl: 1, textTransform: "capitalize" }}>{yearToEdit ? "Update Year" : "Create Year"}</div>
        </Button>
      </ValidatorForm>
      </SimpleCard>
    </Box>
  );
};

export default CreateYear;
