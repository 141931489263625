

import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Icon,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Breadcrumb, SimpleCard } from 'app/components';
import { useLocation, useNavigate } from "react-router-dom";

// Styled components
const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const CreateDeviceClassroom = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);

  const [deviceList, setDeviceList] = useState([]);  // State for device numbers
  const [classroomList, setClassroomList] = useState([]);  // State for classrooms
  const [state, setState] = useState({
    selectedDevice: "", // Holds selected device
    selectedClassroom: "", // Holds selected classroom name
  });

  // Fetch devices and classrooms from the backend
  useEffect(() => {
    const fetchDevicesAndClassrooms = async () => {
      try {
        // Fetch terminal serial numbers
        const deviceResponse = await fetch('https://attendance.api.lissomtech.in/api/terminal-sn');
        const devicesData = await deviceResponse.json();
        const devices = devicesData.map(device => ({
          deviceId: device.sn,
          deviceName: device.sn
        }));
        setDeviceList(devices);

        // Fetch classrooms based on employee ID
        const storedId = sessionStorage.getItem("ID");
        const classroomResponse = await fetch(`https://attendance.api.lissomtech.in/api/classrooms?empId=${storedId}`);
        if (!classroomResponse.ok) {
          throw new Error('Failed to fetch classrooms');
        }
        const classroomsData = await classroomResponse.json();
        setClassroomList(classroomsData);

        // If deviceId is present in location state, fetch its details
        const deviceId = location.state?.deviceId; // deviceId should be sent from the edit button click
        if (deviceId) {
          await fetchDeviceDetails(deviceId); // Fetch the device details for editing
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDevicesAndClassrooms(); // Call fetch on component mount
  }, [location.state]); // Include location.state in dependencies to refetch when it changes

  // Function to fetch device details based on deviceId
  const fetchDeviceDetails = async (deviceId) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/classroom-devices/${deviceId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch device details');
      }
  
      const deviceData = await response.json();
      console.log("Classroom Name from Device Data:", deviceData.ClassRoom); // Log the classroom name
  
      // Update state with fetched device details
      setState((prevState) => ({
        ...prevState,
        selectedDevice: deviceData.device_id || "",
        selectedClassroom: deviceData.ClassRoom || "",
      }));
  
      setIsEditing(true); // Set editing mode
      console.log("Updated State:", {
        selectedDevice: deviceData.device_id,
        selectedClassroom: deviceData.ClassRoom,
      });
    } catch (error) {
      console.error('Error fetching device details:', error);
    }
  };
  

  // Handle form submit
// Handle form submit
const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      deviceId: state.selectedDevice,
      classroom: state.selectedClassroom,
    };
  
    console.log("Submitting Form Data:", formData); // Log the data being sent
  
    try {
      const response = isEditing
        ? await fetch(`https://attendance.api.lissomtech.in/api/assign-device/${state.selectedDevice}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
          })
        : await fetch('https://attendance.api.lissomtech.in/api/assign-device', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
          });
  
      if (!response.ok) {
        const errorMessage = await response.text(); // Capture the error response
        throw new Error(errorMessage);
      }
  
      console.log(isEditing ? 'Device updated successfully' : 'Device assigned successfully');
      navigate('/devicef'); // Redirect after success
    } catch (error) {
      console.error("Error assigning/updating device:", error.message); // Log the error message
    }
  };
  
  

  // Handle change in dropdown
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "selectedClassroom") {
      const classroom = classroomList.find(classroom => classroom.ID === value);
      setState({ 
        ...state, 
        selectedClassroom: classroom ? classroom.ClassRoom : "" // Update selected classroom name
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const { selectedDevice, selectedClassroom } = state;

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Device", path: "/device" }, { name: "Assign Device to Classroom" }]} />
      </Box>

      <SimpleCard title="Assign Device to Classroom">
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              {/* Device Dropdown */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="device-select-label">Select Device</InputLabel>
                <Select
                  labelId="device-select-label"
                  id="device-select"
                  name="selectedDevice"
                  value={selectedDevice}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                >
                  {deviceList.map((device) => (
                    <MenuItem key={device.deviceId} value={device.deviceId}>
                      {device.deviceName} {/* Display the terminal serial number */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Classroom Dropdown */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="classroom-select-label">Select Classroom</InputLabel>
                <Select
  labelId="classroom-select-label"
  id="classroom-select"
  name="selectedClassroom"
  value={classroomList.find(classroom => classroom.ClassRoom === selectedClassroom)?.ID || ""} // Ensure this matches the classroom ID
  onChange={handleChange}
  variant="outlined"
  fullWidth
>
  {classroomList.map((classroom) => (
    <MenuItem key={classroom.ID} value={classroom.ID}>
      {classroom.ClassRoom} {/* Display the classroom name */}
    </MenuItem>
  ))}
</Select>

              </FormControl>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              {/* Any additional fields or logic can go here */}
            </Grid>
          </Grid>

          <Button color="primary" variant="contained" type="submit">
            <Icon>send</Icon>
            <Span sx={{ pl: 1, textTransform: "capitalize" }}>Assign Device</Span>
          </Button>
        </ValidatorForm>
      </SimpleCard>
    </Box>
  );
};

export default CreateDeviceClassroom;
