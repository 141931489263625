import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import SimpleMenu from './Simplemenu';
import { LuFileEdit } from "react-icons/lu";
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));
const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  '& table': {
    minWidth: 600, 
  },
}));
const SubjectRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), 
}));

// Modal style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function SubjectFirstPage() {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [empID, setEmpID] = useState(null);
  const [professors, setProfessors] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedProfessors, setSelectedProfessors] = useState([]);
  const [cretaeBy, setCreateBy] = useState('');

// In useEffect, fetch CreateBy from session storage
useEffect(() => {
    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
        setCreateBy(storedCreateBy); // Update state with Createby
    }
}, []);

  useEffect(() => {
    const storedEmpID = sessionStorage.getItem('ID');
    if (storedEmpID) {
      setEmpID(storedEmpID);
      console.log('EmpID:', storedEmpID); // Check the value of EmpID
      fetchSubjects(storedEmpID); 
      fetchProfessors(); // Fetch professors on mount
    }
  }, []);
  

  const fetchSubjects = async (empID) => {
    if (!empID) {
      console.error('EmpID is not set in session storage.');
      return;
    }

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/subject/${empID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch subjects');
      }
      const data = await response.json();
      setSubjects(data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const fetchProfessors = async () => {
    if (!empID) {
        console.error("EmpID is null or undefined.");
        return;
    }
    try {
        const response = await fetch(`https://attendance.api.lissomtech.in/api/professors/${empID}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProfessors(data);
    } catch (error) {
        console.error("Error fetching professors:", error);
    }
};


useEffect(() => {
    fetchProfessors();
}, [empID]);


  const handleCreateClick = () => {
    navigate('/createsubject');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = async (subject) => {
    navigate('/createsubject', { state: { subject } });
  };

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/subjects/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }),
      });

      if (response.ok) {
        setSubjects((prevSubjects) =>
          prevSubjects.map((subject) =>
            subject.ID === id ? { ...subject, Status: newStatus } : subject
          )
        );
        console.log('Update successful for subject ID:', id);
      } else {
        console.error('Failed to update subject status');
      }
    } catch (error) {
      console.error('Error updating subject status:', error);
    }
  };

  const handleDeleteClick = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this subject?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/subjects/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setSubjects(subjects.filter(subject => subject.ID !== id));
        console.log('Subject deleted successfully');
      } else {
        console.error('Failed to delete subject');
      }
    } catch (error) {
      console.error('Error deleting subject:', error);
    }
  };

  // Modal handling for professor allocation
  const handleAllocationClick = (subject) => {
    setSelectedSubject(subject);
    setSelectedProfessors([]); // Reset selected professors
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

//   const handleSaveAllocation = async () => {
//     if (!selectedSubject || selectedProfessors.length === 0) {
//         console.error('No subject or professors selected for allocation');
//         return;
//     }

//     // Prepare the data for the backend
//     const professorsData = selectedProfessors.map(profID => {
//         // Find the professor in the array to get additional data
//         const professor = professors.find(p => p.ID === profID);
//         return {
//             ProfID: professor.ID,
//             ProfStatus: professor.Status, // Get dynamic ProfStatus
//            // Get dynamic SubjectStatus
//             Status: true, // This can also be dynamic based on your logic
//         };
//     });

//     try {
//         const response = await fetch(`https://attendance.api.lissomtech.in/api/subjects/allocation`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 professors: professorsData, // Send array of professor data
//                 SubjectID: selectedSubject.ID,
//                 SubjectStatus: selectedSubject.Status,
//                 CreatedBy: cretaeBy, // Change as needed
//                 CreatebyID: empID, // Assuming this is the ID of the user making the allocation
//             }),
//         });

//         if (response.ok) {
//             console.log('Allocation saved successfully');
//             handleClose(); // Close the modal
//             // Optionally refresh subjects or fetch updated data here
//             fetchSubjects(empID); // Re-fetch subjects to reflect changes
//         } else {
//             const errorData = await response.json();
//             console.error('Failed to save allocation:', errorData.message || 'Unknown error');
//         }
//     } catch (error) {
//         console.error('Error saving allocation:', error);
//     }
// };


const handleSaveAllocation = async () => {
  if (!selectedSubject || selectedProfessors.length === 0) {
      console.error('No subject or professors selected for allocation');
      return;
  }

  // Prepare the data for the backend
  const professorsData = selectedProfessors.map(profID => {
      const professor = professors.find(p => p.ID === profID);
      return {
          ProfID: professor.ID,
          ProfStatus: professor.Status, // Get dynamic ProfStatus
          Status: true, // This can also be dynamic based on your logic
      };
  });

  // Log selectedSubject and its status for debugging
  console.log('Selected Subject:', selectedSubject);
  console.log('Selected Subject Status:', selectedSubject.Status);

  try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/subjects/allocation`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              professors: professorsData, // Send array of professor data
              SubjectID: selectedSubject.ID,
              SubjectStatus: selectedSubject.Status || null, // Ensure status is not null
              CreatedBy: cretaeBy || 'YourName', // Use dynamic CreatedBy
              CreatebyID: empID, // Assuming this is the ID of the user making the allocation
          }),
      });

      if (response.ok) {
          console.log('Allocation saved successfully');
          handleClose(); // Close the modal
          fetchSubjects(empID); // Re-fetch subjects to reflect changes
      } else {
          const errorData = await response.json();
          console.error('Failed to save allocation:', errorData.message || 'Unknown error');
      }
  } catch (error) {
      console.error('Error saving allocation:', error);
  }
};


  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Subject", path: "/createsubject" }, { name: "Subject List" }]} />
      </Box>
      <SubjectRow>
        <SimpleMenu />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Subject
        </Button>
      </SubjectRow>
      <br />

      <SimpleCard title="Subject List">
        <ScrollableTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Subject Name</TableCell>
                <TableCell align="center">Department</TableCell>
                <TableCell align="center">Semester</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subjects
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((subject) => (
                  <TableRow key={subject.ID}>
                    <TableCell align="left">{subject.SubjectName}</TableCell>
                    <TableCell align="center">{subject.Department}</TableCell>
                    <TableCell align="center">{subject.Semester}</TableCell>
                    <TableCell align="center">
                      {subject.Status === true || subject.Status === 1 ? (
                        <Icon sx={{ color: 'green' }}>check_circle</Icon>
                      ) : (
                        <Icon sx={{ color: 'red' }}>cancel</Icon>
                      )}
                    </TableCell>
                    <TableCell align="center">{new Date(subject.CreateDate).toLocaleDateString()}</TableCell>
                    <TableCell align="center">
                      <IconButton color="primary" onClick={() => handleEditClick(subject)}>
                        <LuFileEdit style={{ fontSize: '30px' }} />
                      </IconButton>
                     
                      <Switch
                        checked={subject.Status === true || subject.Status === 1}
                        onChange={() => handleStatusChange(subject.ID, subject.Status)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />

<IconButton color="warning"  onClick={() => handleAllocationClick(subject)}>
          <Icon style={{ fontSize: '30px' }}>people</Icon>
          {/* <Icon style={{ fontSize: '30px' }}>people_outline</Icon> */}
        </IconButton>
                       {/* <Button variant="contained" color="inherit" onClick={() => handleAllocationClick(subject)}>Allocate Prof</Button> */}
                  
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </ScrollableTableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={subjects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </SimpleCard>

      <Modal open={open} onClose={handleClose}>
    <Box sx={modalStyle}>
        <h6>Allocate Professors to {selectedSubject?.SubjectName}</h6>
        <br />
        <FormControl fullWidth>
            <InputLabel id="professor-select-label">Select Professors</InputLabel>
            <Select
                label="professor-select-label"
                multiple
                value={selectedProfessors}
                onChange={(e) => setSelectedProfessors(e.target.value)}
                renderValue={(selected) => {
                    // Map through selected IDs and find corresponding names
                    return selected
                        .map((id) => {
                            const professor = professors.find((p) => p.ID === id);
                            return professor ? `${professor.FirstName} ${professor.LastName}` : '';
                        })
                        .join(', '); // Join names with a comma
                }}
            >
                {professors.map((professor) => (
                    <MenuItem key={professor.ID} value={professor.ID}>
                        {professor.FirstName} {professor.LastName}
                    </MenuItem>
                ))}
            </Select>
            <br></br>
        </FormControl>
       
        <Button variant="contained" color="primary" onClick={handleSaveAllocation}>
            Save Allocation
        </Button>
    </Box>
</Modal>



    </Box>
  );
}

export default SubjectFirstPage;
