

import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {Breadcrumb,SimpleCard} from 'app/components';
import { useLocation, useNavigate } from "react-router-dom";

// Styled components
const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const CreateDepartment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const departmentToEdit = location.state?.department; // Get the department to edit from state
  const [id, setId] = useState(null);
  const [createBy, setCreateBy] = useState(null);
  const [state, setState] = useState({
    departmentName: departmentToEdit ? departmentToEdit.Department : "",
    description: departmentToEdit ? departmentToEdit.Description : "",
    status: departmentToEdit ? departmentToEdit.Status : false,
    createdDate: departmentToEdit ? new Date(departmentToEdit.CreateDate) : new Date(),
   
    termsAgreed: false,
  });

  useEffect(() => {
    // Retrieve ID from session storage and set it to state
    const storedId = sessionStorage.getItem("ID");
    if (storedId) {
        setId(storedId); // Update state with the ID
    }

    // Retrieve Createby from session storage and set it to state
    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
        setCreateBy(storedCreateBy); // Update state with Createby
    }
}, []);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const currentDateTime = new Date().toISOString(); 
    // Build the new department object
    const newDepartment = {
      departmentName: state.departmentName,
      description: state.description,
      status: state.status,
      createdDate: departmentToEdit ? state.createdDate.toISOString() : currentDateTime, // Ensure UTC format
      empId: id, // Use the session ID as EmpID
      designation: sessionStorage.getItem("Createby"),
      updateDate: currentDateTime, // Set the current date for UpdateDate
    };
  
    try {
      let response;
      if (departmentToEdit) {
        // Update existing department (PUT request)
        response = await fetch(`https://attendance.api.lissomtech.in/api/departments/${departmentToEdit.ID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newDepartment),
        });
      } else {
        // Create new department (POST request)
        response = await fetch('https://attendance.api.lissomtech.in/api/departments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newDepartment),
        });
      }
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      console.log('Department saved with ID:', result.id);
      navigate('/department/department'); // Redirect to the department list
    } catch (error) {
      console.error('Error saving department:', error);
    }
  };
  

  
  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, createdDate: date });

  const { departmentName, description, status, createdDate, empId, designation, termsAgreed } = state;

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: departmentToEdit ? "Edit Department" : "Create Department" }]} />
      </Box>
     
      {/* <SimpleCard title="User ID">
                <Box sx={{ mb: 2 }}>
                    <strong>ID: </strong> {id}
                </Box>
                <Box sx={{ mb: 2 }}>
                    <strong>Created By: </strong> {createBy} 
                </Box>
            </SimpleCard> */}


      <SimpleCard title={departmentToEdit ? "Edit Department" : "Create Department"}>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="departmentName"
              value={departmentName || ""}
              onChange={handleChange}
              errorMessages={["this field is required"]}
              label="Department Name"
              validators={["required"]}
            />

            <TextField
              type="text"
              name="description"
              value={description || ""}
              onChange={handleChange}
              label="Department Description"
              validators={["required"]}
              errorMessages={["this field is required"]}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={status}
                  onChange={(event) => setState({ ...state, status: event.target.checked })}
                />
              }
              label="Status (Active/Inactive)"
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={createdDate}
                onChange={handleDateChange}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Created Date"
                    id="mui-pickers-date"
                    sx={{ mb: 2, width: "100%" }}
                  />
                )}
              />
            </LocalizationProvider>

       

          
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={termsAgreed}
                  onChange={(event) => setState({ ...state, termsAgreed: event.target.checked })}
                />
              }
              label="I have read and agree to the terms of service."
            /> */}
          </Grid>
        </Grid>

        <Button color="primary" variant="contained" type="submit">
          <Icon>send</Icon>
          <Span sx={{ pl: 1, textTransform: "capitalize" }}>{departmentToEdit ? "Update Department" : "Create Department"}</Span>
        </Button>
      </ValidatorForm>
      </SimpleCard>
    </Box>
  );
};

export default CreateDepartment;
