import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Box, Button, Checkbox, FormControlLabel, Grid, Icon, styled } from "@mui/material";
import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Breadcrumb, SimpleCard } from 'app/components';
import { useLocation, useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';

// Styled components
const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const CreateSubject = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subjectToEdit = location.state?.subject;
  const [id, setId] = useState(null);
  const [createBy, setCreateBy] = useState(null);
  const [semesters, setSemesters] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState(subjectToEdit ? { ID: subjectToEdit.SemesterID, SemesterName: subjectToEdit.Semester } : null);

  const [state, setState] = useState({
    subjectName: subjectToEdit ? subjectToEdit.SubjectName : "",
    status: subjectToEdit ? subjectToEdit.Status : false,
    createdDate: subjectToEdit ? new Date(subjectToEdit.CreatedDate) : new Date(),
    departmentID: subjectToEdit ? subjectToEdit.DepartmentID : "",
    department: subjectToEdit ? subjectToEdit.Department : "",
    termsAgreed: false,
  });

  useEffect(() => {
    const storedId = sessionStorage.getItem("ID");
    if (storedId) {
      setId(storedId);
    }

    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
      setCreateBy(storedCreateBy);
    }

    const fetchSemesters = async (empID) => {
      try {
        const response = await fetch(`https://attendance.api.lissomtech.in/api/semester/${empID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch semester');
        }
        const data = await response.json();
        setSemesters(data);
      } catch (error) {
        console.error('Error fetching semester:', error);
      }
    };

    const fetchDepartments = async (empID) => {
      try {
        const response = await fetch(`https://attendance.api.lissomtech.in/api/departments/${empID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch departments');
        }
        const data = await response.json();
        setDepartments(data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    const empID = sessionStorage.getItem('ID');
    if (empID) {
      fetchSemesters(empID);
      fetchDepartments(empID);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currentDateTime = new Date().toISOString();

    const finalCreatedDate = state.createdDate instanceof Date && !isNaN(state.createdDate)
      ? state.createdDate.toISOString()
      : currentDateTime;

    const newSubject = {
      subjectName: state.subjectName,
      status: state.status,
      createDate: subjectToEdit ? finalCreatedDate : currentDateTime,
      empId: id,
      designation: sessionStorage.getItem("Createby"),
      departmentID: state.departmentID,
      department: state.department,
      semester: selectedSemester?.SemesterName,
      semesterID: selectedSemester?.ID
    };

    console.log("Submitting:", newSubject);

    try {
      let response;
      if (subjectToEdit) {
        response = await fetch(`https://attendance.api.lissomtech.in/api/subjects/${subjectToEdit.ID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newSubject),
        });
      } else {
        response = await fetch('https://attendance.api.lissomtech.in/api/subjects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newSubject),
        });
      }

      if (response.ok) {
        console.log('Subject saved successfully');
        navigate('/subject');
      } else {
        console.error('Failed to save subject');
      }
    } catch (error) {
      console.error('Error saving subject:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      setState({ ...state, createdDate: date });
    } else {
      console.error('Invalid date selected');
    }
  };

  const handleDepartmentChange = (event, value) => {
    if (value) {
      setState({ ...state, departmentID: value.ID, department: value.Department });
    } else {
      setState({ ...state, departmentID: "", department: "" });
    }
  };

  const { subjectName, status, createdDate, departmentID, department, termsAgreed } = state;

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Subject", path: "/subject" }, { name: subjectToEdit ? "Edit Subject" : "Create Subject" }]} />
      </Box>

      <SimpleCard title={subjectToEdit ? "Edit Subject" : "Create Subject"}>
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                type="text"
                name="subjectName"
                value={subjectName || ""}
                onChange={handleChange}
                errorMessages={["this field is required"]}
                label="Subject Name"
                validators={["required"]}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={status}
                    onChange={(event) => setState({ ...state, status: event.target.checked })}
                  />
                }
                label="Status (Active/Inactive)"
              />

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={createdDate}
                  onChange={handleDateChange}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="Created Date"
                      id="mui-pickers-date"
                      sx={{ mb: 2, width: "100%" }}
                    />
                  )}
                />
              </LocalizationProvider>

              <Autocomplete
                options={departments}
                getOptionLabel={(option) => option.Department || ""}
                value={departments.find(dept => dept.ID === departmentID) || null} // Bind the value to show the selected department
                onChange={handleDepartmentChange}
                renderInput={(params) => (
                  <TextField {...params} label="Department" variant="outlined" />
                )}
              />

              <Autocomplete
                options={semesters}
                getOptionLabel={(option) => option.SemesterName || ""}
                value={selectedSemester} // Bind the value to show the selected semester
                onChange={(e, value) => setSelectedSemester(value || null)}
                renderInput={(params) => (
                  <TextField {...params} label="Select Semester" variant="outlined" />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAgreed}
                    onChange={(event) => setState({ ...state, termsAgreed: event.target.checked })}
                  />
                }
                label="I have read and agree to the terms of service."
              /> */}
            </Grid>
          </Grid>

          <Button color="primary" variant="contained" type="submit">
            <Icon>send</Icon>
            <Span sx={{ pl: 1, textTransform: "capitalize" }}>{subjectToEdit ? "Update Subject" : "Create Subject"}</Span>
          </Button>
        </ValidatorForm>
      </SimpleCard>
    </Box>
  );
};

export default CreateSubject;
