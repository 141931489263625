import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import SimpleMenu from './Simplemenu';
import '../css/department.css';
import { LuFileEdit } from "react-icons/lu";
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const DepartmentRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));

function DepartmentFirstPage() {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [empID, setEmpID] = useState(null);

  useEffect(() => {
    // Retrieve EmpID from session storage
    const storedEmpID = sessionStorage.getItem('ID');
    if (storedEmpID) {
      setEmpID(storedEmpID);
      fetchDepartments(storedEmpID); // Fetch departments using EmpID
    }
  }, []);

  const fetchDepartments = async (empID) => {
    if (!empID) {
      console.error('EmpID is not set in session storage.');
      return;
    }

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/departments/${empID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch departments');
      }
      const data = await response.json();
      console.log('Fetched departments:', data);
      setDepartments(data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const handleCreateClick = () => {
    navigate('/department/Createdepartment');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = (department) => {
    navigate('/department/Createdepartment', { state: { department } });
  };

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0
    console.log('Toggling status for ID:', id, 'New Status:', newStatus); // Debugging log

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/departments/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }), // Update the status
      });

      if (response.ok) {
        console.log('Update successful for department ID:', id, 'New Status:', newStatus);
        setDepartments((prevDepartments) =>
          prevDepartments.map((dept) =>
            dept.ID === id ? { ...dept, Status: newStatus } : dept
          )
        );
      } else {
        console.error('Failed to update department status');
      }
    } catch (error) {
      console.error('Error updating department status:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Department" }]} />
      </Box>
  
      <DepartmentRow>
        <SimpleMenu />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Department
        </Button>
      </DepartmentRow>
      <br />

      <SimpleCard title="Department List">
        <ScrollableTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Department Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created Date</TableCell>
                {/* <TableCell align="center">Employee ID</TableCell>
                <TableCell align="center">Designation</TableCell> */}
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((department) => (
                  <TableRow key={department.ID}>
                    <TableCell align="left">{department.Department}</TableCell>
                    <TableCell align="center">{department.Description}</TableCell>
                    <TableCell align="center">
                    {department.Status === true || department.Status === 1 ? (
                        <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon
                      ) : (
                        <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon
                      )}
                    </TableCell>
                    <TableCell align="center">{new Date(department.CreateDate).toLocaleDateString()}</TableCell>
                    {/* <TableCell align="center">{department.EmpID}</TableCell>
                    <TableCell align="center">{department.Designation}</TableCell> */}
                    <TableCell align="center">
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <IconButton color="primary" onClick={() => handleEditClick(department)}>
                          <LuFileEdit style={{ fontSize: '30px' }} />
                        </IconButton>
                        <Switch
                       checked={department.Status === true || department.Status === 1}
                          onChange={() => handleStatusChange(department.ID, department.Status)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </ScrollableTableContainer>

        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={departments.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default DepartmentFirstPage;
