import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";



import materialRoutes from "app/views/material-kit/MaterialRoutes";
import DepartmentPage from "./views/Department/Component/Department";
import Createdepartment from "./views/Department/Component/Createdepartment"; // Adjust the path accordingly
import Classroom from "./views/Classroom/Classroom";
import CreateClassroom from "./views/Classroom/Createclassroom";
import Phase from './views/Phase/component/Phase'
import Createdegree from './views/Phase/component/Createdegree';
import Degree from './views/Phase/component/Degree';
import Year from './views/Phase/component/Year';
import CreateYear from "./views/Phase/component/Createyear";
import Semester from './views/Phase/component/Semester';
import CreateSemester from './views/Phase/component/Createsemester';
import Subject  from './views/mysetting/Subject';
import Createsubject from './views/mysetting/Createsubject';
import Prefix from './views/mysetting/Prefix';
import Createprefix from './views/mysetting/Createprefix';
import SettingsPage from "./views/mysetting/settingfirstpage";
import PhaseFirstPage from "./views/Phase/component/PhaseFirstpage";
import CreatePhase from "./views/Phase/component/CreatePhase";
import Premises from './views/Premises/component/Premises';
import CreatePremises from "./views/Premises/component/Createpremises";
import Timetablefirst from "./views/Timetable/timetablefirst";
import Createtimetable from "./views/Timetable/createtimetable";
import Dailyreport from "./views/Reports/dailyreport";
import Professorreport from "./views/Reports/professorreport";
import Studentreport from "./views/Reports/studentreport";
import CreateDeviceClassroom from "./views/Devices/CreateDeviceClassroom";
import DeviceFirstPage from "./views/Devices/DeviceFirstPage";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));

// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const Studentfirstpage = Loadable(lazy(() => import("./views/student/studentfirstpage")));
const Professorfirstpage = Loadable(lazy(() => import("./views/professor/professorfirstpage")));
const CreateProfessor = Loadable(lazy(() => import("./views/professor/createprofessor")));
const CreateStudent = Loadable(lazy(() => import("./views/student/createstudent")));
const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      { path: "/dashboard/default", element: <Analytics />, auth: authRoles.admin },
      
      // e-chart route
      { path: "/charts/echarts", element: <AppEchart />, auth: authRoles.editor },
      { path: "/dashboard/default", element: <Analytics />, auth: authRoles.admin },
      { path: "/student/studentfirstpage", element: <Studentfirstpage />, auth: authRoles.admin },
      { path: "/professor/professorfirstpage", element: <Professorfirstpage />, auth: authRoles.admin },
      { path: "/professor/createprofessor", element: <CreateProfessor />, auth: authRoles.admin },
      { path: "/professor/updateprofessor/:id", element: <CreateProfessor />, auth: authRoles.admin },
      { path: "/student/createstudent", element: <CreateStudent />, auth: authRoles.admin },
      { path: "/student/updatestudent/:id", element: <CreateStudent />, auth: authRoles.admin },
      { path: "/department/department", element: <DepartmentPage />, auth: authRoles.admin },
      { path: "/mysetting/settingpage", element: <SettingsPage />, auth: authRoles.admin }, // Route for Create Department
      { path: "/classroom", element: <Classroom />, auth: authRoles.admin }, // Route for Create Department
      { path: "/classroom/Createclassroom", element: <CreateClassroom />, auth: authRoles.admin },
      { path: "/department/Createdepartment", element: <Createdepartment />, auth: authRoles.admin },
      { path: '/Phase',element:<Phase></Phase>, auth: authRoles.admin} ,// Route for Create Department
      { path: '/degree',element:<Degree></Degree>, auth: authRoles.admin} ,
      { path: '/createdegree',element:<Createdegree></Createdegree>,auth: authRoles.admin},
      { path: '/year',element:<Year></Year>, auth: authRoles.admin} ,
      { path: '/createyear',element:<CreateYear></CreateYear>, auth: authRoles.admin} ,
      { path: '/semester',element:<Semester></Semester>, auth: authRoles.admin} ,
      { path: '/createsemester',element:<CreateSemester></CreateSemester>, auth: authRoles.admin},
      { path: '/Subject' ,element:<Subject></Subject>, auth: authRoles.admin},
      { path: '/createsubject', element:<Createsubject></Createsubject>, auth: authRoles.admin},
      { path: '/prefix' ,element:<Prefix></Prefix>, auth: authRoles.admin},
      { path: '/createprefix', element:<Createprefix></Createprefix>, auth: authRoles},
      { path: '/phasepage', element:<PhaseFirstPage></PhaseFirstPage>, auth: authRoles},
      { path: '/createphase', element:<CreatePhase></CreatePhase>, auth: authRoles},
      { path: '/premises', element:<Premises></Premises>, auth: authRoles},
      { path: '/createpremises', element:<CreatePremises></CreatePremises>,auth: authRoles},
      { path: '/updatepremises/:id', element:<CreatePremises></CreatePremises>,auth: authRoles},
      { path: '/timetable', element:<Timetablefirst></Timetablefirst>,auth: authRoles},
      { path: '/createtimetable', element:<Createtimetable></Createtimetable>,auth: authRoles},
      { path: '/createtimetable/:timetableId', element: <Createtimetable />, auth: authRoles },
      { path: '/dailyreport', element: <Dailyreport />, auth: authRoles },
      { path: '/professorreport', element: <Professorreport />, auth: authRoles },
      { path: '/studentreport', element: <Studentreport />, auth: authRoles },
      { path: '/createdevice', element: <CreateDeviceClassroom />, auth: authRoles },
      { path: '/devicef', element: <DeviceFirstPage />, auth: authRoles },
      

     
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="/session/signin" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
