import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LuFileEdit } from "react-icons/lu";
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));

const PremisesRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

function PremisesFirstPage() {
  const navigate = useNavigate();
  const [premises, setPremises] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [empID, setEmpID] = useState(null);

  // Fetch premises data from API
  useEffect(() => {
    const storedEmpID = sessionStorage.getItem('ID');
    if (storedEmpID) {
      setEmpID(storedEmpID);
      fetchPremises(storedEmpID); // Fetch departments using EmpID
    }
  }, []);

  const fetchPremises = async (empID) => {
    if (!empID) {
      console.error('EmpID is not set in session storage.');
      return;
    }

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/premises/${empID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch departments');
      }
      const data = await response.json();
      console.log('Fetched departments:', data);
      setPremises(data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  // Fetch the premises details by ID
  const fetchPremiseById = async (id) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/premises/${id}`);
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
        throw new Error('Failed to fetch premise');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching premise by ID:', error);
    }
  };

  const handleCreateClick = () => {
    navigate('/createpremises');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = (id) => {
    navigate(`/updatepremises/${id}`); // Pass the premise ID in the URL
  };

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = !currentStatus; // Toggle between true and false
  
    // Log the values for debugging
    console.log(`Updating status for ID: ${id}, New Status: ${newStatus}`);
  
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/premises/update/status/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }), // Send true/false instead of 1/0
      });
  
      if (response.ok) {
        // If the update is successful, update the premises state
        setPremises((prevPremises) =>
          prevPremises.map((prem) =>
            prem.ID === id ? { ...prem, Status: newStatus } : prem
          )
        );
      } else {
        const errorMessage = await response.text(); // Read the error message from the response
        console.error('Failed to update premise status:', errorMessage);
      }
    } catch (error) {
      console.error('Error updating premise status:', error);
    }
  };
  
  


  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Premises" }]} />
      </Box>
      {/* <Box>
         <h2>Premises Page</h2>
      </Box> */}

      <PremisesRow>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Premise
        </Button>
      </PremisesRow>
      <br />

      <SimpleCard title="Premises List">
        <ScrollableTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="center">Premise Name</TableCell>
                <TableCell align="center">Department</TableCell>
                <TableCell align="center">Classroom</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {premises
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((premise) => (
                  <TableRow key={premise.ID}>
                    <TableCell align="center">{premise.PremisesName}</TableCell>
                    <TableCell align="center">{premise.Department}</TableCell>
                    <TableCell align="center">{premise.Classroom}</TableCell>
                  
                    <TableCell align="center">
  {premise.Status ? (
    <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon for true
  ) : (
    <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon for false
  )}
</TableCell>

<TableCell align="center">{new Date(premise.CreatedDate).toLocaleDateString()}</TableCell>
                 
                    <TableCell align="center">
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <IconButton color="primary" onClick={() => handleEditClick(premise.ID)}>
                          <LuFileEdit style={{ fontSize: '30px' }} />
                        </IconButton>
                        <Switch
  checked={premise.Status} // Active if Status is true
  onChange={() => handleStatusChange(premise.ID, premise.Status)}
  inputProps={{ "aria-label": "primary checkbox" }}
/>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </ScrollableTableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={premises.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default PremisesFirstPage;
