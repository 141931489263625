


// import React, { useState, useEffect } from 'react';
// import { Breadcrumb, SimpleCard } from "app/components";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   styled,
//   Box,
//   CircularProgress,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogTitle,
// } from '@mui/material';
// import axios from 'axios';
// import jsPDF from 'jspdf';
// import * as XLSX from 'xlsx';
// import autoTable from 'jspdf-autotable';
// import 'jspdf-autotable';
// const Container = styled("div")(({ theme }) => ({
//   margin: "30px",
//   [theme.breakpoints.down("sm")]: { margin: "16px" },
//   "& .breadcrumb": {
//     marginBottom: "30px",
//     [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
//   }
// }));


// const StyledTable = styled(Table)(() => ({
//   whiteSpace: "pre",
//   "& thead": {
//     "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
//   },
//   "& tbody": {
//     "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
//   }
// }));

// const ScrollableTableContainer = styled(Box)(({ theme }) => ({
//   overflowX: "auto",
//   // Ensure the table doesn't shrink too much on small screens
//   '& table': {
//     minWidth: 600, // You can adjust this value based on your design needs
//   },
// }));

// const Studentreport = () => {
//   const [professors, setProfessors] = useState([]);
//   const [timetableData, setTimetableData] = useState({});
//   const [studentNamesData, setStudentNamesData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const empID = sessionStorage.getItem('ID');
//     if (empID) {
//       fetchProfessors(empID);
//     } else {
//       console.error('EmpID not found in session storage');
//       setError('EmpID not found in session storage');
//       setLoading(false);
//     }
//   }, []);

//   const fetchProfessors = async (empID) => {
//     try {
//       const response = await fetch(`https://attendance.api.lissomtech.in/api/professors/${empID}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch professors');
//       }
//       const data = await response.json();
  
//       const filteredProfessors = [];
//       await Promise.all(data.map(async (prof) => {
//         const timetable = await fetchTodayTimetable(prof.ID);
//         if (timetable && timetable.timetable.length > 0) {
//           filteredProfessors.push({ ...prof, timetable: timetable.timetable });
//         }
//       }));
  
//       // Sort professors based on the start time of their first class
//       filteredProfessors.sort((a, b) => {
//         const aFirstClassTime = a.timetable.length > 0 ? new Date(a.timetable[0].StartTime) : null;
//         const bFirstClassTime = b.timetable.length > 0 ? new Date(b.timetable[0].StartTime) : null;
  
//         return aFirstClassTime - bFirstClassTime; // Sort by ascending time
//       });
  
//       setProfessors(filteredProfessors);
//     } catch (error) {
//       console.error("Error fetching professors:", error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   // const fetchProfessors = async (empID) => {
//   //   try {
//   //     const response = await fetch(`https://attendance.api.lissomtech.in/api/professors/${empID}`);
//   //     if (!response.ok) {
//   //       throw new Error('Failed to fetch professors');
//   //     }
//   //     const data = await response.json();

//   //     const filteredProfessors = [];
//   //     await Promise.all(data.map(async (prof) => {
//   //       const timetable = await fetchTodayTimetable(prof.ID);
//   //       if (timetable && timetable.timetable.length > 0) {
//   //         filteredProfessors.push(prof);
//   //       }
//   //     }));

//   //     setProfessors(filteredProfessors);
//   //   } catch (error) {
//   //     console.error("Error fetching professors:", error);
//   //     setError(error.message);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };

//   const fetchTodayTimetable = async (professorId) => {
//     try {
//       const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetablestud/today/${professorId}`);
//       const todayTimetable = response.data;

//       setTimetableData(prevData => ({
//         ...prevData,
//         [professorId]: todayTimetable
//       }));

//       setStudentNamesData(prevData => ({
//         ...prevData,
//         [professorId]: todayTimetable.students
//       }));

//       return todayTimetable;
//     } catch (error) {
//       console.error('Error fetching today\'s timetable:', error);
//       return null;
//     }
//   };

//   const formatTimeToIST = (utcTime) => {
//     const utcDate = new Date(utcTime);
//     const istOffset = 5 * 60 + 30; // IST offset is UTC+5:30
//     const istDate = new Date(utcDate.getTime() - istOffset * 60 * 1000);
//     return istDate.toLocaleTimeString('en-US', {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//     });
//   };

//   return (
//     <Container>
//     <Box className="breadcrumb">
//       <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "student Report" }]} />
//     </Box>

//     <SimpleCard >
//       <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//         <Typography variant="h6"> Today's Students Report MBBS sem 1</Typography>
//         <Button variant="contained" color="primary" >
//           Download Report
//         </Button>
//       </Box>
//       <ScrollableTableContainer>
//       <StyledTable>
//           <TableHead>
//             <TableRow>
//               <TableCell>Professor</TableCell>
//               <TableCell>Subject</TableCell>
//               <TableCell>Time</TableCell>
//               <TableCell>Student Name</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {professors.map((prof) => {
//               const timetable = timetableData[prof.ID]?.timetable || [];
//               const studentNames = studentNamesData[prof.ID] || [];

//               const studentRows = studentNames.map(student => {
//                 const classesForStudent = timetable.filter(entry => entry.StudentID === student.ID);
//                 return { student, classes: classesForStudent };
//               });

//               return studentRows.map(({ student, classes }, index) => (
//                 <TableRow key={`${prof.ID}-${student.ID}`}>
//                   {index === 0 && (
//                     <>
//                       <TableCell rowSpan={studentRows.length}>{`${prof.FirstName} ${prof.LastName}`}</TableCell>
//                       <TableCell rowSpan={studentRows.length}>
//                         {classes.length > 0 ? classes.map(entry => entry.Subject).join(", ") : "No classes today"}
//                       </TableCell>
//                       <TableCell rowSpan={studentRows.length}>
//                         {classes.length > 0
//                           ? classes.map(entry => `${formatTimeToIST(entry.StartTime)} - ${formatTimeToIST(entry.EndTime)}`).join(", ")
//                           : "No classes today"}
//                       </TableCell>
//                     </>
//                   )}
//                   <TableCell>{`${student.FirstName} ${student.LastName}`}</TableCell>
//                 </TableRow>
//               ));
//             })}
//           </TableBody>
//         </StyledTable>
//         </ScrollableTableContainer>
//         </SimpleCard>
     
//       {/* Rest of your component code, including the dialog for downloading */}
//     </Container>
//   );
// };

// export default Studentreport;







import React, { useState, useEffect } from 'react';
import { Breadcrumb, SimpleCard } from "app/components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Box,
  CircularProgress,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable';

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
  }
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  '& table': {
    minWidth: 600,
  },
}));

const Studentreport = () => {
  const [professors, setProfessors] = useState([]);
  const [timetableData, setTimetableData] = useState({});
  const [studentNamesData, setStudentNamesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // State for search inputs
  const [subjectSearch, setSubjectSearch] = useState('');
  const [studentSearch, setStudentSearch] = useState('');

  useEffect(() => {
    const empID = sessionStorage.getItem('ID');
    if (empID) {
      fetchProfessors(empID);
    } else {
      console.error('EmpID not found in session storage');
      setError('EmpID not found in session storage');
      setLoading(false);
    }
  }, []);

  const fetchProfessors = async (empID) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/professors/${empID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch professors');
      }
      const data = await response.json();
      const filteredProfessors = [];
      
      await Promise.all(data.map(async (prof) => {
        const timetable = await fetchTodayTimetable(prof.ID);
        if (timetable && timetable.timetable.length > 0) {
          filteredProfessors.push({ ...prof, timetable: timetable.timetable });
        }
      }));
      
      filteredProfessors.sort((a, b) => {
        const aFirstClassTime = a.timetable.length > 0 ? new Date(a.timetable[0].StartTime) : null;
        const bFirstClassTime = b.timetable.length > 0 ? new Date(b.timetable[0].StartTime) : null;
        return aFirstClassTime - bFirstClassTime; 
      });
  
      setProfessors(filteredProfessors);
    } catch (error) {
      console.error("Error fetching professors:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTodayTimetable = async (professorId) => {
    try {
      const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetablestud/today/${professorId}`);
      const todayTimetable = response.data;

      setTimetableData(prevData => ({
        ...prevData,
        [professorId]: todayTimetable
      }));

      setStudentNamesData(prevData => ({
        ...prevData,
        [professorId]: todayTimetable.students
      }));

      return todayTimetable;
    } catch (error) {
      console.error('Error fetching today\'s timetable:', error);
      return null;
    }
  };

  const formatTimeToIST = (utcTime) => {
    const utcDate = new Date(utcTime);
    const istOffset = 5 * 60 + 30; 
    const istDate = new Date(utcDate.getTime() - istOffset * 60 * 1000);
    return istDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  // Function to filter professors based on search criteria
  const filteredProfessors = professors.filter(prof => {
    const timetable = timetableData[prof.ID]?.timetable || [];
    const subjects = timetable.map(entry => entry.Subject).join(", ");
    const studentNames = studentNamesData[prof.ID] || [];

    return (
      subjects.toLowerCase().includes(subjectSearch.toLowerCase()) &&
      studentNames.some(student => `${student.FirstName} ${student.LastName}`.toLowerCase().includes(studentSearch.toLowerCase()))
    );
  });


  // function formatTimeToI(dateTimeString) {
  //   const date = new Date(dateTimeString);
  
  //   // Convert UTC to IST (Indian Standard Time is UTC+5:30)
  //   const istOffset = 5 * 60 + 30; // 5 hours 30 minutes in minutes
  //   const istDate = new Date(date.getTime() - istOffset * 60 * 1000);
  
  //   return istDate.toLocaleTimeString('en-GB', { hour12: false }); // 24-hour format
  // }

  
  function formatTimeToI(dateTimeString) {
    const date = new Date(dateTimeString);
  
    // Convert UTC to IST (Indian Standard Time is UTC+5:30)
    const istOffset = 5 * 60 + 30; // 5 hours 30 minutes in minutes
    const istDate = new Date(date.getTime() - istOffset * 60 * 1000);
  
    // Format date and time with '-' for date and ',' for separation
    const formattedDate = istDate
      .toLocaleDateString('en-GB') // Returns in dd/mm/yyyy
      .replace(/\//g, '-'); // Replace / with -
  
    const formattedTime = istDate.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  
    return `${formattedDate}, ${formattedTime}`;
  }
  
  

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Student Report" }]} />
      </Box>

      <SimpleCard>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Today's Students Report MBBS sem 1</Typography>
          {/* <Button variant="contained" color="primary" >
            Download Report
          </Button> */}
        </Box>
        
        {/* Search Fields */}
        {/* <Box display="flex" mb={2}>
          <TextField
            variant="outlined"
            label="Search by Subject"
            value={subjectSearch}
            onChange={(e) => setSubjectSearch(e.target.value)}
            style={{ marginRight: '16px' }}
          />
          <TextField
            variant="outlined"
            label="Search by Student Name"
            value={studentSearch}
            onChange={(e) => setStudentSearch(e.target.value)}
          />
        </Box> */}


        
        
        <ScrollableTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Professor</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Punch Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {filteredProfessors.map((prof) => {
    const timetable = timetableData[prof.ID]?.timetable || [];
    const studentNames = studentNamesData[prof.ID] || [];

    const studentRows = studentNames.map(student => {
      const classesForStudent = timetable.filter(entry => entry.StudentID === student.ID);
      return { student, classes: classesForStudent };
    });

    return studentRows.map(({ student, classes }, index) => (
      <TableRow key={`${prof.ID}-${student.ID}`}>
        {index === 0 && (
          <>
            <TableCell rowSpan={studentRows.length}>{`${prof.FirstName} ${prof.LastName}`}</TableCell>
            <TableCell rowSpan={studentRows.length}>
              {classes.length > 0 ? classes.map(entry => entry.Subject).join(", ") : "No classes today"}
            </TableCell>
            <TableCell rowSpan={studentRows.length}>
              {classes.length > 0
                ? classes.map(entry => `${formatTimeToIST(entry.StartTime)} - ${formatTimeToIST(entry.EndTime)}`).join(", ")
                : "No classes today"}
            </TableCell>
          </>
        )}
        <TableCell>{`${student.FirstName} ${student.LastName}`}</TableCell>
        <TableCell>
          {student.punch_time ? formatTimeToI(student.punch_time) : "No punch time"}
        </TableCell>
      </TableRow>
    ));
  })}
</TableBody>

          </StyledTable>
        </ScrollableTableContainer>
      </SimpleCard>
    </Container>
  );
};

export default Studentreport;
