import React from 'react';
import { Breadcrumb } from "app/components";
import { Box, styled } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../css/Phase.css'; // Ensure the updated CSS file is imported

const SettingsPage = () => {
  const navigate = useNavigate();

  // STYLED COMPONENTS
  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
    }
  }));

  

  const handleNavigation = (route) => {
    navigate(route);
  };

  // Handlers for Degree, Year, and Semester Clicks
  const handleDegreeClick = () => {
    // Logic for Degree click, e.g., open modal or navigate
    console.log("Degree card clicked");
  };

  const handleYearClick = () => {
    // Logic for Year click, e.g., open modal or navigate
    console.log("Year card clicked");
  };

  const handleSemesterClick = () => {
    // Logic for Semester click, e.g., open modal or navigate
    console.log("Semester card clicked");
  };

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Settings", path: "/settings" }, { name: "Settings Details" }]} />
      </Box>

      <div className="settings-container">
        <h3 className="header-title">Setting Details</h3>
        <div className="settings-card-container">
          
        <motion.div
            className="settings-degree-card"
            onClick={() => handleNavigation('/phasepage')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >

<h2>Phase</h2>
<p>Set up academic Phase</p>
          </motion.div>

   {/* Degree Card */}
   <motion.div
            className="settings-degree-card"
            onClick={() => handleNavigation('/degree')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >


       <h2>Degree</h2>
            <p>Select degrees (e.g., Engineering, MBBS)</p>
          </motion.div>

       {/* Year Card */}
          <motion.div
            className="settings-year-card"
            onClick={() => handleNavigation('/year')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            <h2>Year</h2>
            <p>Set up academic years</p>
          </motion.div>

          {/* Semester Card */}
          <motion.div
            className="settings-semester-card"
            onClick={() => handleNavigation('/semester')}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
             <h2>Semester</h2>
            <p>Manage semester details</p>
          </motion.div>
        </div>
      </div>
    </Container>
  );
};

export default SettingsPage;
