import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Icon,
    styled,
    TextField,
} from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components"; // Adjust path as necessary
import { Autocomplete } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

// Styled components
const AutoComplete = styled(Autocomplete)(() => ({ width: "100%", marginBottom: "16px" }));
const StyledTextField = styled(TextField)(() => ({ width: "100%", marginBottom: "16px" }));
const StyledTextArea = styled('textarea')(() => ({
    width: "100%",
    height: "100px",
    marginBottom: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    padding: "8px",
    resize: "none", // Disable resizing
}));

const CreatePremises = () => {
    const { id } = useParams(); // Get ID from URL parameters
    const navigate = useNavigate();
    
    // Assuming empID is stored in session or local storage
    const empID = sessionStorage.getItem('ID');

    const [Department, setDepartment] = useState("");
    const [classroom, setClassroom] = useState(""); // Store classroom name
    const [premisesName, setPremisesName] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [status, setStatus] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [classrooms, setClassrooms] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Capture the current date for created and updated dates
        const currentDate = new Date().toISOString();

        const newPremises = {
            Department,
            depid: departments.find(dep => dep.Department === Department)?.ID || null, // Assuming you have ID for the Department
            classroom: classroom || null, // Store classroom name
            classroomid: classrooms.find(cls => cls.ClassRoom === classroom)?.ID || null, // Get ID based on classroom name
            premisesName: premisesName || null,
            additionalInfo: additionalInfo || null,
            status,
            CreatedDate: !id ? currentDate : undefined,
            UpdatedDate: id ? currentDate : undefined,
            empID: empID, // Get from sessionStorage
            Designation: "", // Add this if necessary; update as per your requirements
        };

        console.log("Payload to server:", newPremises); // Log to see the data being sent

        try {
            const response = id
                ? await fetch(`https://attendance.api.lissomtech.in/api/onepremises/${id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(newPremises),
                })
                : await fetch('https://attendance.api.lissomtech.in/api/premises', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(newPremises),
                });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to save premises:', errorData); // Log the error response
            } else {
                console.log('Premises saved successfully');
                navigate('/premises');
            }
        } catch (error) {
            console.error('Error saving premises:', error);
        }
    };

    useEffect(() => {
        const empID = sessionStorage.getItem('ID');
        if (!empID) {
            console.error('EmpID is not available in sessionStorage');
            return; // Exit early if empID is not available
        }

        const fetchDepartments = async (empID) => {
            console.log("Fetching departments for empID:", empID);
            try {
                const response = await fetch(`https://attendance.api.lissomtech.in/api/departments/${empID}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch departments');
                }
                const data = await response.json();
                setDepartments(data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        const fetchClassrooms = async () => {
            const storedId = sessionStorage.getItem("ID"); // Assuming this is your session ID
            console.log("Fetching classrooms for empID:", storedId); // Log stored ID

            try {
                const response = await fetch(`https://attendance.api.lissomtech.in/api/classrooms?empId=${storedId}`);
                console.log("Classrooms API response:", response); // Log raw response

                if (!response.ok) {
                    throw new Error('Failed to fetch classrooms');
                }

                const data = await response.json();
                console.log('Fetched classrooms data:', data); // Log fetched data
                setClassrooms(data);
            } catch (error) {
                console.error('Error fetching classrooms:', error);
            }
        };

        const fetchPremisesToEdit = async (id) => {
            try {
                const response = await fetch(`https://attendance.api.lissomtech.in/api/onepremises/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch premises');
                }
                const data = await response.json();
                setDepartment(data.Department);
                setClassroom(data.Classroom);
                setPremisesName(data.PremisesName);
                setAdditionalInfo(data.AdditionalInfo);
                setStatus(Boolean(data.Status));
            } catch (error) {
                console.error('Error fetching premises:', error);
            }
        };

        if (empID) {
            fetchDepartments(empID);
            fetchClassrooms();
            if (id) {
                fetchPremisesToEdit(id);
            }
        } else {
            console.error("EmpID is not available in sessionStorage");
        }
    }, [empID, id]); // Re-run the effect only if empID or id changes

    return (
        <Box margin="30px">
            <Breadcrumb
                routeSegments={[{ name: "Premises", path: "/premises" }, { name: id ? "Edit Premises" : "Create Premises" }]}
            />
            <SimpleCard title={id ? "Edit Premises" : "Create Premises"}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <AutoComplete
                                options={departments}
                                getOptionLabel={(option) => option.Department || ""} // Check if 'Department' field exists
                                onChange={(e, value) => setDepartment(value?.Department || "")}
                                value={departments.find(dep => dep.Department === Department) || null}
                                renderInput={(params) => (
                                    <StyledTextField {...params} label="Department" variant="outlined" />
                                )}
                            />

                            <AutoComplete
                                options={classrooms}
                                getOptionLabel={(option) => option.ClassRoom || ""}
                                onChange={(e, value) => setClassroom(value?.ClassRoom || "")} // Set to classroom name
                                value={classrooms.find(cls => cls.ClassRoom === classroom) || null}
                                renderInput={(params) => (
                                    <StyledTextField {...params} label="Classroom" variant="outlined" />
                                )}
                            />

                            <StyledTextField
                                label="Premises Name"
                                value={premisesName}
                                onChange={(e) => setPremisesName(e.target.value)}
                                variant="outlined"
                            />

                            <StyledTextArea
                                placeholder="Additional Information"
                                value={additionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                variant="outlined"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={status}
                                        onChange={(e) => setStatus(e.target.checked)}
                                    />
                                }
                                label="Status (Active/Inactive)"
                            />
                        </Grid>
                    </Grid>

                    <Button color="primary" variant="contained" type="submit">
                        <Icon>send</Icon>
                        {id ? "Update Premises" : "Create Premises"}
                    </Button>
                </form>
            </SimpleCard>
        </Box>
    );
};

export default CreatePremises;
