import React from 'react'
import { Breadcrumb } from "app/components";
import { Box, styled } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

function Dailyreport() {
    const navigate = useNavigate();

    // STYLED COMPONENTS
    const Container = styled("div")(({ theme }) => ({
      margin: "30px",
      [theme.breakpoints.down("sm")]: { margin: "16px" },
      "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
      }
    }));
  
    const handleNavigation = (route) => {
      navigate(route);
    };
  return (
    <Container>
    <Box className="breadcrumb">
      <Breadcrumb routeSegments={[{ name: "Reports", path: "/reports" }, { name: "Reports" }]} />
    </Box>

    <div className="settings-container">
      <h3 className="header-title">Reports Details</h3>
      <div className="settings-card-container">
    

        <motion.div
          className="settings-subject-card"
          onClick={() => handleNavigation('/professorreport')}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <h2>Professor Reports</h2>
          <p></p>
        </motion.div>

        <motion.div
          className="settings-prefix-card"
          onClick={() => handleNavigation('/studentreport')}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
           <h2>Students Reports</h2>
          <p></p>
        </motion.div>
      </div>
    </div>
  </Container>
  )
}

export default Dailyreport
