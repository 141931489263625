// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { Breadcrumb } from "app/components";
// import { Box, Table, TableRow, TableBody, TableHead, TableCell, styled, Container } from "@mui/material";
// import axios from 'axios';

// const StyledTable = styled(Table)(() => ({
//   whiteSpace: "pre",
//   "& thead": {
//     "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
//   },
//   "& tbody": {
//     "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
//   },
// }));

// function Professorreport() {
//   const [timetableData, setTimetableData] = useState([]);
//   const { professorId } = useParams();

//   useEffect(() => {
//     const fetchTimetableData = async () => {
//       try {
//         const response = await axios.get('https://attendance.api.lissomtech.in/api/timetabletoday');
//         setTimetableData(response.data);
//       } catch (error) {
//         console.error('Error fetching timetable:', error);
//       }
//     };

//     fetchTimetableData();
//   }, [professorId]);

//   return (
//     <Box margin="30px">
//       <Box className="breadcrumb" mb={3}>
//         <Breadcrumb routeSegments={[{ name: "Professorreport", path: "/material" }, { name: "Professorreport" }]} />
//       </Box>
//       <Container>
//         <div className="row">
//           <div className="col-md-12">
//             <p>Today Lecture</p>
//             <StyledTable>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Time</TableCell>
//                   <TableCell>Subject</TableCell>
//                   <TableCell>Professor</TableCell>
//                   <TableCell>Status</TableCell> {/* New column for status */}
//                   <TableCell>Total Time (min)</TableCell> {/* Total Time Column */}
//                   <TableCell>Present Students</TableCell> {/* New column for present students */}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {timetableData.map((item, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       {new Date(item.StartTime).toLocaleTimeString()} - {new Date(item.EndTime).toLocaleTimeString()}
//                     </TableCell>
//                     <TableCell>{item.Subject}</TableCell>
//                     <TableCell>{item.Professor}</TableCell>
//                     <TableCell>{item.isProfessorPresent ? "Present" : "Absent"}</TableCell> {/* Display presence status */}
//                     <TableCell>{item.totalTime}</TableCell> {/* Display total time */}
//                     <TableCell>{item.presentCount}</TableCell> {/* Display present students */}
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </StyledTable>
//           </div>
//           <div className="col-md-7">
//             {/* Add any additional details here */}
//           </div>
//         </div>
//       </Container>
//     </Box>
//   );
// }

// export default Professorreport;




// import React, { useState, useEffect } from 'react';
// import { Breadcrumb, SimpleCard } from "app/components";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   styled,
//   Box,
//   CircularProgress,
//   Typography
// } from '@mui/material';
// import axios from 'axios';

// // STYLED COMPONENTS
// const Container = styled("div")(({ theme }) => ({
//   margin: "30px",
//   [theme.breakpoints.down("sm")]: { margin: "16px" },
//   "& .breadcrumb": {
//     marginBottom: "30px",
//     [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
//   }
// }));

// const StyledTable = styled(Table)(() => ({
//   whiteSpace: "pre",
//   "& thead": {
//     "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
//   },
//   "& tbody": {
//     "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
//   }
// }));

// const ScrollableTableContainer = styled(Box)(({ theme }) => ({
//   overflowX: "auto",
//   // Ensure the table doesn't shrink too much on small screens
//   '& table': {
//     minWidth: 600, // You can adjust this value based on your design needs
//   },
// }));

// const ProfessorReport = () => {
//   const [professors, setProfessors] = useState([]);
//   const [timetableData, setTimetableData] = useState({});
//   const [studentCountData, setStudentCountData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const empID = sessionStorage.getItem('ID'); // Fetch empID from session
//     if (empID) {
//       fetchProfessors(empID);
//     } else {
//       console.error('EmpID not found in session storage');
//       setError('EmpID not found in session storage');
//       setLoading(false);
//     }
//   }, []);

//   const fetchProfessors = async (empID) => {
//     try {
//       const response = await fetch(`https://attendance.api.lissomtech.in/api/professors/${empID}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch professors');
//       }
//       const data = await response.json();
//       setProfessors(data);

//       // Fetch today's timetable and attendance for each professor
//       await Promise.all(data.map(prof => fetchTodayTimetable(prof.ID, prof.EmpID)));
//     } catch (error) {
//       console.error("Error fetching professors:", error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchTodayTimetable = async (professorId, empID) => {
//     try {
//       const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetable/today/${professorId}`);
//       const todayTimetable = response.data;
//       setTimetableData(prevData => ({
//         ...prevData,
//         [professorId]: todayTimetable
//       }));
//     } catch (error) {
//       console.error('Error fetching today\'s timetable:', error);
//     }
//   };

//   const renderTimetable = (professorId) => {
//     const timetable = timetableData[professorId];
//     console.log("Timetable for professor ID", professorId, ":", timetable); // Debugging log
//     if (!timetable || !Array.isArray(timetable.timetable) || timetable.timetable.length === 0) {
//       return "No classes today";
//     }
//     return timetable.timetable.map((entry, idx) => {
//       const utcStartTime = new Date(entry.StartTime);
//       const utcEndTime = new Date(entry.EndTime);
//       const istOffset = 5 * 60 + 30; // IST is UTC + 5 hours 30 minutes
//       const startTimeIST = new Date(utcStartTime.getTime() - istOffset * 60 * 1000);
//       const endTimeIST = new Date(utcEndTime.getTime() - istOffset * 60 * 1000);

//       const startTime = startTimeIST.toLocaleTimeString('en-US', {
//         hour: '2-digit',
//         minute: '2-digit',
//         hour12: true,
//       });

//       const endTime = endTimeIST.toLocaleTimeString('en-US', {
//         hour: '2-digit',
//         minute: '2-digit',
//         hour12: true,
//       });

//       return (
//         <div key={idx}>
//           <strong>{entry.Subject}</strong>: {startTime} - {endTime}
//         </div>
//       );
//     });
//   };

//   const renderAttendance = (professorId) => {
//     const attendance = timetableData[professorId]?.attendance || {};
//     const inTime = attendance.InTime || "Not clocked in";
//     const outTime = attendance.OutTime || "Not clocked out";

//     return (
//       <div>
//         In: {inTime}<br />
//         Out: {outTime}
//       </div>
//     );
//   };

//   const renderStudentCount = (professorId) => {
//     const studentCount = studentCountData[professorId];
//     return studentCount ? studentCount : "No students";
//   };

//   if (loading) {
//     return (
//       <Container>
//         <Box className="breadcrumb">
//           <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Professor Report" }]} />
//         </Box>
//         <Box display="flex" justifyContent="center" mt={5}>
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Box className="breadcrumb">
//           <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Professor Report" }]} />
//         </Box>
//         <Box display="flex" justifyContent="center" mt={5}>
//           <Typography color="error">{error}</Typography>
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container>
//       <Box className="breadcrumb">
//         <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Professor Report" }]} />
//       </Box>

//       <SimpleCard title="Professor Report">
//       <ScrollableTableContainer>
//       <StyledTable>
//             <TableHead>
//               <TableRow>
              
//                 <TableCell> Name</TableCell>
//                 <TableCell>Today's Timetable</TableCell>
//                 <TableCell>Attendance (In/Out)</TableCell>
//                 <TableCell>Student Count</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {professors.map((prof, index) => (
//                 <TableRow key={index}>
               
                
//                   <TableCell>{prof.FirstName}{prof.LastName}</TableCell>
//                   <TableCell>
//                     {renderTimetable(prof.ID)}
//                   </TableCell>
//                   <TableCell>
//                     {renderAttendance(prof.ID)}
//                   </TableCell>
//                   <TableCell>
//                     {renderStudentCount(prof.ID)}
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </StyledTable>
//         </ScrollableTableContainer>
//       </SimpleCard>
//     </Container>
//   );
// };

// export default ProfessorReport;




// import React, { useState, useEffect } from 'react';
// import { Breadcrumb, SimpleCard } from "app/components";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   styled,
//   Box,
//   CircularProgress,
//   Typography
// } from '@mui/material';
// import axios from 'axios';

// // STYLED COMPONENTS
// const Container = styled("div")(({ theme }) => ({
//   margin: "30px",
//   [theme.breakpoints.down("sm")]: { margin: "16px" },
//   "& .breadcrumb": {
//     marginBottom: "30px",
//     [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
//   }
// }));
// const StyledTable = styled(Table)(() => ({
//   whiteSpace: "pre",
//   "& thead": {
//     "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
//   },
//   "& tbody": {
//     "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
//   },
// }));

// const ProfessorReport = () => {
//   const [professors, setProfessors] = useState([]);
//   const [timetableData, setTimetableData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

  

//   useEffect(() => {
//     const empID = sessionStorage.getItem('ID'); // Fetch empID from session
//     if (empID) {
//       fetchProfessors(empID);
//     } else {
//       console.error('EmpID not found in session storage');
//       setError('EmpID not found in session storage');
//       setLoading(false);
//     }
//   }, []);

//   const fetchProfessors = async (empID) => {
//     try {
//       const response = await fetch(`https://attendance.api.lissomtech.in/api/professors/${empID}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch professors');
//       }
//       const data = await response.json();
//       setProfessors(data);

//       // Fetch today's timetable for each professor
//       await Promise.all(data.map(prof => fetchTodayTimetable(prof.ID)));
//     } catch (error) {
//       console.error("Error fetching professors:", error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchTodayTimetable = async (professorId) => {
//     try {
//       const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetable/today/${professorId}`);
//       const todayTimetable = response.data;
//       setTimetableData(prevData => ({
//         ...prevData,
//         [professorId]: todayTimetable
//       }));
//     } catch (error) {
//       console.error('Error fetching today\'s timetable:', error);
//     }
//   };

//   const renderTimetable = (professorId) => {
//     const timetable = timetableData[professorId];
//     if (!timetable || !Array.isArray(timetable.timetable) || timetable.timetable.length === 0) {
//       return "No classes today";
//     }

//     return timetable.timetable.map((entry, idx) => {
//       const utcStartTime = new Date(entry.StartTime);
//       const utcEndTime = new Date(entry.EndTime);
//       const istOffset = 5 * 60 + 30; // IST is UTC + 5 hours 30 minutes
//       const startTimeIST = new Date(utcStartTime.getTime() - istOffset * 60 * 1000);
//       const endTimeIST = new Date(utcEndTime.getTime() - istOffset * 60 * 1000);

//       const startTime = startTimeIST.toLocaleTimeString('en-US', {
//         hour: '2-digit',
//         minute: '2-digit',
//         hour12: true,
//       });

//       const endTime = endTimeIST.toLocaleTimeString('en-US', {
//         hour: '2-digit',
//         minute: '2-digit',
//         hour12: true,
//       });

//       return (
//         <div key={idx}>
//           <strong>{entry.Subject}</strong>: {startTime} - {endTime}
//         </div>
//       );
//     });
//   };

//   const formatDate = (date) => {
//     return date.toISOString().slice(0, 19).replace('T', ' '); // Converts to YYYY-MM-DD HH:mm:ss
//   };

//   const renderAttendance = (professorId) => {
//     const attendance = timetableData[professorId]?.attendance || {};
//     const inTime = attendance.InTime ? formatDate(new Date(attendance.InTime)) : "Not clocked in";
//     const outTime = attendance.OutTime ? formatDate(new Date(attendance.OutTime)) : "Not clocked out";



//     const isPresent = inTime !== "Not clocked in" && outTime !== "Not clocked out";
//     return (
//       <div>
//         In: {inTime}<br />
//         Out: {outTime}<br />
//         Status: {isPresent ? "Present" : "Absent"}
//       </div>
//     );
//   };

//   // Filter professors who have classes today
//   const filteredProfessors = professors.filter(prof => {
//     const todayTimetable = timetableData[prof.ID];
//     return todayTimetable && Array.isArray(todayTimetable.timetable) && todayTimetable.timetable.length > 0;
//   });

 

//   if (loading) {
//     return (
//       <Container>
//         <Box className="breadcrumb">
//           <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Professor Report" }]} />
//         </Box>
//         <Box display="flex" justifyContent="center" mt={5}>
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Box className="breadcrumb">
//           <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Professor Report" }]} />
//         </Box>
//         <Box display="flex" justifyContent="center" mt={5}>
//           <Typography color="error">{error}</Typography>
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container>
//       <Box className="breadcrumb">
//         <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Professor Report" }]} />
//       </Box>

//       <SimpleCard title="Professor Report">
//         <TableContainer>
//           <StyledTable>
//             <TableHead>
//               <TableRow>
              
//                 <TableCell>Professor Name</TableCell>
              
//                 <TableCell>Today's Timetable</TableCell>
//                 <TableCell>Attendance (In/Out)</TableCell>
//                 <TableCell>Student Count</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {filteredProfessors.map((prof, index) => (
//                 <TableRow key={index}>
                
//                   <TableCell>{prof.FirstName} {prof.LastName}</TableCell>
               
//                   <TableCell>
//                     {renderTimetable(prof.ID)}
//                   </TableCell>
//                   <TableCell>
//                     {renderAttendance(prof.ID)}
//                   </TableCell>
//                   <TableCell>
//                     {/* Assuming a function or API call to fetch student count exists */}
//                     {/* {renderStudentCount(prof.ID)} */}
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </StyledTable>
//         </TableContainer>
//       </SimpleCard>
//     </Container>
//   );
// };

// export default ProfessorReport;







import React, { useState, useEffect } from 'react';
import { Breadcrumb, SimpleCard } from "app/components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Box,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';

import 'jspdf-autotable';

// STYLED COMPONENTS
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));


const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
  }
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));

const TimetableCell = styled(TableCell)(({ theme }) => ({
  maxWidth: "200px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const RemarkCell = styled(TableCell)(({ isPresent }) => ({
  color: isPresent ? 'green' : 'red',
}));

const ProfessorReport = () => {
  const [professors, setProfessors] = useState([]);
  const [timetableData, setTimetableData] = useState({});
  const [studentCountData, setStudentCountData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State to handle dialog open/close

  useEffect(() => {
    const empID = sessionStorage.getItem('ID'); // Fetch empID from session
    if (empID) {
      fetchProfessors(empID);
    } else {
      console.error('EmpID not found in session storage');
      setError('EmpID not found in session storage');
      setLoading(false);
    }
  }, []);

  const fetchProfessors = async (empID) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/professors/${empID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch professors');
      }
      const data = await response.json();

      const filteredProfessors = [];

      await Promise.all(data.map(async (prof) => {
        const timetable = await fetchTodayTimetable(prof.ID);
        if (timetable && timetable.timetable.length > 0) {
          filteredProfessors.push(prof);
        }
      }));

      setProfessors(filteredProfessors); // Set only professors who have classes today
    } catch (error) {
      console.error("Error fetching professors:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTodayTimetable = async (professorId) => {
    try {
      const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetable/today/${professorId}`);
      const todayTimetable = response.data;

      setTimetableData(prevData => ({
        ...prevData,
        [professorId]: todayTimetable
      }));

      setStudentCountData(prevData => ({
        ...prevData,
        [professorId]: todayTimetable.studentCount
      }));

      return todayTimetable;
    } catch (error) {
      console.error('Error fetching today\'s timetable:', error);
      return null;
    }
  };

  const handleDownload = () => {
    setOpenDialog(true); // Open dialog to choose between PDF or Excel
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Today's Professor Report", 14, 10);
  
    const tableColumn = ["Professor", "Timetable", "Attendance (In/Out)", "Student Count", "Remark"];
    const tableRows = [];
  
    // Prepare the data for each professor
    sortedProfessors.forEach((prof) => {
      // Convert timetable times to IST and format timetable for PDF
      const timetable = timetableData[prof.ID]?.timetable
        .map((entry) => {
          const utcStartTime = new Date(entry.StartTime);
          const utcEndTime = new Date(entry.EndTime);
  
          const istOffset = 5 * 60 + 30; // IST offset is UTC+5:30
          const startTimeIST = new Date(utcStartTime.getTime() - istOffset * 60 * 1000);
          const endTimeIST = new Date(utcEndTime.getTime() - istOffset * 60 * 1000);
  
          const startTime = startTimeIST.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
  
          const endTime = endTimeIST.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
  
          return `${entry.Subject}: ${startTime} - ${endTime}`;
        })
        .join("\n") || "No classes today";
  
      const attendance = renderAttendance(prof.ID).props.children
        .map(item => (item.props ? item.props.children : item))
        .join(" ");
  
      const studentCount = renderStudentCount(prof.ID);
      const remark = renderRemark(prof.ID).text;
  
      const rowData = [
        `${prof.FirstName} ${prof.LastName}`,
        timetable || "No classes today",
        attendance || "Not available",
        studentCount || "No students",
        remark,
      ];
      tableRows.push(rowData);
    });
  
    // Add table to the PDF using autoTable
    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
    });
  
    doc.save("professor_report.pdf");
    
    setOpenDialog(false); // Close the dialog
  };
  
  const formatDate = (date) => {
        return date.toISOString().slice(0, 19).replace('T', ' '); // Converts to YYYY-MM-DD HH:mm:ss
      };

  const downloadExcel = () => {
    const csvData = sortedProfessors.map(prof => {
      // Get the timetable for the professor
      const timetable = timetableData[prof.ID]?.timetable || [];
      
      // Convert timetable entries to a string
      const formattedTimetable = timetable.map(entry => {
        const utcStartTime = new Date(entry.StartTime);
        const utcEndTime = new Date(entry.EndTime);
  
        const istOffset = 5 * 60 + 30; // IST offset is UTC+5:30
        const startTimeIST = new Date(utcStartTime.getTime() + istOffset * 60 * 1000);
        const endTimeIST = new Date(utcEndTime.getTime() + istOffset * 60 * 1000);
  
        const startTime = startTimeIST.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
  
        const endTime = endTimeIST.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
  
        return `${entry.Subject}: ${startTime} - ${endTime}`;
      }).join(", ") || "No classes today";
  
      // Get attendance information
      const attendance = timetableData[prof.ID]?.attendance || {};
      const inTime = attendance.InTime || "Not clocked in";
      const outTime = attendance.OutTime || "Not clocked out";
      const attendanceString = `In: ${inTime}, Out: ${outTime}`;
  
      return {
        FirstName: prof.FirstName,
        LastName: prof.LastName,
        Timetable: formattedTimetable,
        StudentCount: studentCountData[prof.ID] || "No students",
        Remark: renderRemark(prof.ID).text,
        Attendance: attendanceString, // Include attendance details
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Professor Report");
  
    XLSX.writeFile(workbook, "professor_report.xlsx");
    setOpenDialog(false); // Close dialog
  };

  

  
  const renderTimetable = (professorId) => {
    const timetable = timetableData[professorId];
    if (!timetable || !Array.isArray(timetable.timetable) || timetable.timetable.length === 0) {
      return "No classes today";
    }

    const sortedTimetable = timetable.timetable.sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime));

    return sortedTimetable.map((entry, idx) => {
      const utcStartTime = new Date(entry.StartTime);
      const utcEndTime = new Date(entry.EndTime);

      const istOffset = 5 * 60 + 30;
      const startTimeIST = new Date(utcStartTime.getTime() - istOffset * 60 * 1000);
      const endTimeIST = new Date(utcEndTime.getTime() - istOffset * 60 * 1000);

      const startTime = startTimeIST.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      const endTime = endTimeIST.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      return (
        <div key={idx}>
          <strong>{entry.Subject}</strong>
          <br /> {startTime} - {endTime}
        </div>
      );
    });
  };

  const renderAttendance = (professorId) => {
        const attendance = timetableData[professorId]?.attendance || {};
        const inTime = attendance.InTime ? formatDate(new Date(attendance.InTime)) : "Not clocked in";
        const outTime = attendance.OutTime ? formatDate(new Date(attendance.OutTime)) : "Not clocked out";

    return (
      <div>
        <strong> In:</strong> {inTime}<br />
        <strong> Out:</strong> {outTime}
      </div>
    );
  };

  const renderStudentCount = (professorId) => {
    const studentCount = studentCountData[professorId];
    return studentCount ? studentCount : "No students";
  };

  const sortedProfessors = professors.map((prof) => {
    // Get the professor's timetable for sorting
    const timetable = timetableData[prof.ID]?.timetable || [];
    // Find the earliest start time
    const earliestClass = timetable.reduce((earliest, entry) => {
      const startTime = new Date(entry.StartTime);
      return startTime < earliest ? startTime : earliest;
    }, new Date('2100-01-01')); // Start with a very far future date
  
    return {
      ...prof,
      earliestClass, // Add the earliest class time to the professor object
    };
  }).sort((a, b) => a.earliestClass - b.earliestClass); // Sort professors based on earliest class
  
  

  const renderRemark = (professorId) => {
    const attendance = timetableData[professorId]?.attendance || {};
    const inTime = attendance.InTime;
    const outTime = attendance.OutTime;

    if (inTime && outTime) {
      return { text: "Present", isPresent: true };
    } else if (inTime) {
      return { text: "Clocked In, Absent", isPresent: false };
    } else {
      return { text: "Absent", isPresent: false };
    }
  };

  if (loading) {
    return (
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }]} />
        </Box>
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }]} />
        </Box>
        <Box display="flex" justifyContent="center" mt={5}>
          <Typography color="error">{error}</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Professor Report" }]} />
      </Box>

      <SimpleCard >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6"> Today's Professor Report MBBS sem 1</Typography>
          <Button variant="contained" color="primary" onClick={handleDownload}>
            Download Report
          </Button>
        </Box>
        <ScrollableTableContainer>
        <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name Of Professor</TableCell>
                <TimetableCell align="left">Today's Timetable</TimetableCell>
                <TableCell align="left">Attendance (In/Out)</TableCell>
                <TableCell align="left">Student Count</TableCell>
                <TableCell align="left">Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {professors.map((prof, index) => {
                const { text, isPresent } = renderRemark(prof.ID);
                return (
                  <TableRow key={index}>
                    <TableCell align="left">{prof.FirstName} {prof.LastName}</TableCell>
                    <TableCell align="left">{renderTimetable(prof.ID)}</TableCell>
                    <TableCell align="left">{renderAttendance(prof.ID)}</TableCell>
                    <TableCell align="left">{renderStudentCount(prof.ID)}</TableCell>
                    <RemarkCell align="left" isPresent={isPresent}>{text}</RemarkCell>
                  </TableRow>
                );
              })} */}
              {sortedProfessors.map((prof, index) => {
  const { text, isPresent } = renderRemark(prof.ID);
  return (
    <TableRow key={index}>
      <TableCell align="left">{prof.FirstName} {prof.LastName}</TableCell>
      <TableCell align="left">{renderTimetable(prof.ID)}</TableCell>
      <TableCell align="left">{renderAttendance(prof.ID)}</TableCell>
      <TableCell align="left">{renderStudentCount(prof.ID)}</TableCell>
      <RemarkCell align="left" isPresent={isPresent}>{text}</RemarkCell>
    </TableRow>
  );
})}

            </TableBody>
          </StyledTable>
        </ScrollableTableContainer>
      </SimpleCard>

      {/* Dialog for PDF or Excel download */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Choose Report Format</DialogTitle>
        <DialogActions>
          <Button onClick={downloadPDF} color="primary">
            Download PDF
          </Button>
          <Button onClick={downloadExcel} color="primary">
            Download Excel
          </Button>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProfessorReport;
