import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LuFileEdit } from "react-icons/lu";
import SimpleMenu from './Simplemenu'; // Assuming SimpleMenu is shared
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));


const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));
const SemesterRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

function SemesterFirstPage() {
  const navigate = useNavigate();
  const [semesters, setSemesters] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [empID, setEmpID] = useState(null);
  // Fetch semester data from API
  // Fetch all semesters
  useEffect(() => {
    // Retrieve EmpID from session storage
    const storedEmpID = sessionStorage.getItem('ID');
    if (storedEmpID) {
      setEmpID(storedEmpID);
      fetchSemesters(storedEmpID); // Fetch departments using EmpID
    }
  }, []);
  const fetchSemesters = async (empID) => {
    if (!empID) {
        console.error('EmpID is not set in session storage.');
        return;
    }

    try {
        const response = await fetch(`https://attendance.api.lissomtech.in/api/semester/${empID}`);
        if (!response.ok) {
            throw new Error('Failed to fetch semester');
        }
        const data = await response.json();
        console.log('Fetched semester data:', data); // Check the fetched data structure
        setSemesters(data);
    } catch (error) {
        console.error('Error fetching semester:', error);
    }
};


// Fetch the semester details by ID
const fetchSemesterById = async (id) => {
  try {
    const response = await fetch(`https://attendance.api.lissomtech.in/api/semesters/${id}`);
    if (!response.ok) {
      console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
      throw new Error('Failed to fetch semester');
    }
    const data = await response.json();
    
    // Convert boolean Status to 1 or 0 if needed
    const updatedData = {
      ...data,
      Status: data.Status ? 1 : 0, // Assuming Status is boolean in the database
    };

    console.log('Fetched semester by ID:', updatedData); // Log fetched data for debugging
    return updatedData;
  } catch (error) {
    console.error('Error fetching semester by ID:', error);
  }
};


  useEffect(() => {
    fetchSemesters(); // Fetch semesters when the component mounts
  }, []);

  const handleCreateClick = () => {
    navigate('/createSemester');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = async (semester) => {
    const fetchedSemester = await fetchSemesterById(semester.ID); // Fetch the semester by ID
    if (fetchedSemester) {
      navigate('/createSemester', { state: { semester: fetchedSemester } });
    }
  };

  // Delete semester by ID
  // const handleDeleteClick = async (id) => {
  //   const confirmDelete = window.confirm('Are you sure you want to delete this semester?');
  //   if (!confirmDelete) return;

  //   try {
  //     const response = await fetch(`https://attendance.api.lissomtech.in/api/semesters/${id}`, {
  //       method: 'DELETE',
  //     });

  //     if (response.ok) {
  //       setSemesters(semesters.filter(semester => semester.ID !== id)); // Update the UI
  //       console.log('Semester deleted successfully');
  //     } else {
  //       console.error('Failed to delete semester');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting semester:', error);
  //   }
  // }; 


  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/semesters/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }), // Update the status
      });

      if (response.ok) {
        setSemesters((prevSemesters) =>
          prevSemesters.map((Semester) =>
          Semester.ID === id ? { ...Semester, Status: newStatus } : Semester
          )
        );
      } else {
        console.error('Failed to update year status');
      }
    } catch (error) {
      console.error('Error updating year status:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Semester", path: "/createSemester" }, { name: "Semester List" }]} />
      </Box>
      <Box>
        {/* <h2>Semester Page</h2> */}
      </Box>

      <SemesterRow>
        <SimpleMenu />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Semester
        </Button>
      </SemesterRow>
      <br />

      <SimpleCard title="Semester List">
      <ScrollableTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">Semester Name</TableCell>
              <TableCell align="center">Year Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created Date</TableCell>
              {/* <TableCell align="center">Employee ID</TableCell> */}
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {semesters
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((semester) => (
      <TableRow key={semester.ID}>
        <TableCell align="left">{semester.SemesterName}</TableCell>
        <TableCell align="center">{semester.YearName}</TableCell>
        <TableCell align="center">{semester.Description}</TableCell>
        <TableCell align="center">
          {semester.Status === true || semester.Status === 1 ? (
            <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon
          ) : (
            <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon
          )}
        </TableCell>
        <TableCell align="center">{new Date(semester.CreateDate).toLocaleDateString()}</TableCell>
        {/* <TableCell align="center">{semester.EmpID}</TableCell> */}
        <TableCell align="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton color="primary" onClick={() => handleEditClick(semester)}>
              <LuFileEdit style={{ fontSize: '30px' }} />
            </IconButton>
            <Switch
              checked={semester.Status === true || semester.Status === 1} // Active if Status is true or 1
              onChange={() => handleStatusChange(semester.ID, semester.Status)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </TableCell>
      </TableRow>
    ))}
</TableBody>

        </StyledTable>
</ScrollableTableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={semesters.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default SemesterFirstPage;


