import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Breadcrumb, SimpleCard } from "app/components";
import { useLocation, useNavigate } from "react-router-dom";

// Styled components
const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const CreateDegree = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const degreeToEdit = location.state?.degree; // Get the degree to edit from state
  const [id, setId] = useState(null);
  const [createBy, setCreateBy] = useState(null);
  const [state, setState] = useState({
    degreeName: degreeToEdit ? degreeToEdit.DegreeName : "",
    description: degreeToEdit ? degreeToEdit.Description : "",
    status: degreeToEdit ? degreeToEdit.Status : false,
    createdDate: degreeToEdit ? new Date(degreeToEdit.CreatedDate) : new Date(),
   
    termsAgreed: false,
  });



  useEffect(() => {
    // Retrieve ID from session storage and set it to state
    const storedId = sessionStorage.getItem("ID");
    if (storedId) {
        setId(storedId); // Update state with the ID
    }

    // Retrieve Createby from session storage and set it to state
    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
        setCreateBy(storedCreateBy); // Update state with Createby
    }
}, []);


  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const currentDateTime = new Date().toISOString();
    
    // Check if createdDate is valid
    const finalCreatedDate = state.createdDate instanceof Date && !isNaN(state.createdDate)
      ? state.createdDate.toISOString()
      : currentDateTime; // Fallback to current time if invalid

    // Build the new degree object
    const newDegree = {
      degreeName: state.degreeName,
      description: state.description,
      status: state.status,
      createdDate: degreeToEdit ? finalCreatedDate : currentDateTime, // Use validated createdDate
      empId: id, // Use the session ID as EmpID
      designation: sessionStorage.getItem("Createby"),
      updateDate: currentDateTime, // Set the current date for UpdateDate
    };

    try {
      let response;
      if (degreeToEdit) {
        // Update existing degree (PUT request)
        response = await fetch(`https://attendance.api.lissomtech.in/api/degrees/${degreeToEdit.ID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newDegree),
        });
      } else {
        // Create new degree (POST request)
        response = await fetch('https://attendance.api.lissomtech.in/api/degrees', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newDegree),
        });
      }

      if (response.ok) {
        console.log('Degree saved successfully');
        navigate('/degree'); // Redirect after successful submission
      } else {
        console.error('Failed to save degree');
      }
    } catch (error) {
      console.error('Error saving degree:', error);
    }
  };
  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, createdDate: date });

  const { degreeName, description, status, createdDate, empId, termsAgreed } = state;

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Degree", path: "/degree" }, { name: degreeToEdit ? "Edit Degree" : "Create Degree" }]} />
      </Box>

      <SimpleCard title={degreeToEdit ? "Edit Degree" : "Create Degree"}>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="degreeName"
              value={degreeName || ""}
              onChange={handleChange}
              errorMessages={["this field is required"]}
              label="Degree Name"
              validators={["required"]}
            />

            <TextField
              type="text"
              name="description"
              value={description || ""}
              onChange={handleChange}
              label="Degree Description"
              validators={["required"]}
              errorMessages={["this field is required"]}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={status}
                  onChange={(event) => setState({ ...state, status: event.target.checked })}
                />
              }
              label="Status (Active/Inactive)"
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={createdDate}
                onChange={handleDateChange}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Created Date"
                    id="mui-pickers-date"
                    sx={{ mb: 2, width: "100%" }}
                  />
                )}
              />
            </LocalizationProvider>

          
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={termsAgreed}
                  onChange={(event) => setState({ ...state, termsAgreed: event.target.checked })}
                />
              }
              label="I have read and agree to the terms of service."
            /> */}
          </Grid>
        </Grid>

        <Button color="primary" variant="contained" type="submit">
          <Icon>send</Icon>
          <Span sx={{ pl: 1, textTransform: "capitalize" }}>{degreeToEdit ? "Update Degree" : "Create Degree"}</Span>
        </Button>
      </ValidatorForm>
      </SimpleCard>
    </Box>
  );
};

export default CreateDegree;
