



import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Grid, Autocomplete ,Alert} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import axios from 'axios';

// Days of the week as an array
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// Utility function to get the date of a specific weekday in the current week
const getDateForWeekday = (selectedWeekday) => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, etc.
  const currentMonday = new Date(today.setDate(today.getDate() - (dayOfWeek - 1))); // Get the Monday of the current week

  // Find the index of the selected day in daysOfWeek
  const selectedDayIndex = daysOfWeek.indexOf(selectedWeekday);
  
  // Calculate the date of the selected day (by adding days to current Monday)
  const selectedDate = new Date(currentMonday);
  selectedDate.setDate(currentMonday.getDate() + selectedDayIndex);

  // Return the date in YYYY-MM-DD format
  return selectedDate.toISOString().split('T')[0];
};

function CreateTimetable() {
  const navigate = useNavigate();
  const { timetableId } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [professors, setProfessors] = useState([]);
  const [createBy, setCreateBy] = useState('');
  const [alertMessage, setAlertMessage] = useState(''); // Store the error message
  const [timetableData, setTimetableData] = useState({
    day: '',
    subject: '',
    SubjectID: '',
    professor: '',
    ProfessorID: '',
    startTime: '',
    endTime: '',
    AttendenceID:''
  });

  const empID = sessionStorage.getItem('ID');
  
  useEffect(() => {
    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
        setCreateBy(storedCreateBy); // Update state with Createby
    }
  }, []);

  // Fetch subjects
  useEffect(() => {
    const fetchSubjects = async () => {
      if (empID) {
        try {
          const response = await axios.get(`https://attendance.api.lissomtech.in/api/subject/${empID}`);
          setSubjects(response.data);
        } catch (error) {
          console.error('Error fetching subjects:', error);
        }
      }
    };
    fetchSubjects();
  }, [empID]);

  // Fetch timetable data if editing
  useEffect(() => {
    const fetchTimetableData = async () => {
      if (timetableId) {
        try {
          const response = await axios.get(`https://attendance.api.lissomtech.in/api/timetablee/${timetableId}`, {
            params: { empID }
          });
          
          if (response.data) {
            const startTime = new Date(response.data.StartTime).toISOString().substring(11, 16);
            const endTime = new Date(response.data.EndTime).toISOString().substring(11, 16);
            setTimetableData({
              day: response.data.WeekDay || '',
              subject: response.data.Subject || '',
              SubjectID: response.data.SubjectID || '',
              professor: response.data.Professor || '',
              ProfessorID: response.data.ProfessorID || '',
              startTime: startTime,
              endTime: endTime,
            });

            // Fetch professors for the subject being edited
            const professorsResponse = await axios.get(`https://attendance.api.lissomtech.in/api/professors/subject/${response.data.SubjectID}`);
            setProfessors(professorsResponse.data);
          }
        } catch (error) {
          console.error('Error fetching timetable data:', error);
        }
      }
    };
    fetchTimetableData();
  }, [timetableId, empID]);

  // Handle subject change
  const handleSubjectChange = async (event, value) => {
    if (value) {
      setTimetableData((prevData) => ({
        ...prevData,
        subject: value.SubjectName,
        SubjectID: value.ID,
      }));
      try {
        const response = await axios.get(`https://attendance.api.lissomtech.in/api/professors/subject/${value.ID}`);
        setProfessors(response.data);
      } catch (error) {
        console.error('Error fetching professors:', error);
      }
    } else {
      setTimetableData((prevData) => ({
        ...prevData,
        subject: '',
        SubjectID: '',
      }));
      setProfessors([]);
    }
  };

  // Handle professor change
  const handleProfessorChange = (event, value) => {
    if (value) {
      setTimetableData((prevData) => ({
        ...prevData,
        professor: `${value.FirstName} ${value.LastName}`,
        ProfessorID: value.ProfessorID,
        AttendenceID:value.AttendenceID,
      }));
    } else {
      setTimetableData((prevData) => ({
        ...prevData,
        professor: '',
        ProfessorID: '',
        AttendenceID:'',
      }));
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTimetableData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const dateForSelectedDay = getDateForWeekday(timetableData.day); // Get the date for the selected weekday

    const dataToSubmit = {
      DateDay: dateForSelectedDay, // Use the calculated date
      WeekDay: timetableData.day,
      StartTime: timetableData.startTime,
      EndTime: timetableData.endTime,
      Subject: timetableData.subject,
      SubjectID: timetableData.SubjectID,
      Professor: timetableData.professor,
      ProfessorID:timetableData.ProfessorID,
      AttendenceID:timetableData.AttendenceID,
      Status: true, // or false based on your logic
      empId: empID,
      designation: createBy,
    };

    try {
      if (timetableId) {
        // Update timetable
        await axios.put(`https://attendance.api.lissomtech.in/api/update/timetable/${timetableId}`, dataToSubmit);
      } else {
        // Create new timetable
        await axios.post('https://attendance.api.lissomtech.in/api/timetable', dataToSubmit);
      }
      navigate('/timetable');
    } catch (error) {
      if (error.response && error.response.data.error) {
        setAlertMessage(error.response.data.error); // Set the alert message
      } else {
        console.error('Error creating or updating timetable:', error);
      }
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Create Timetable" }]} />
      </Box>
      {alertMessage && (
      <Alert onClose={() => setAlertMessage('')} severity="warning" variant="filled">
        {alertMessage}
      </Alert>
    )}
      <SimpleCard title="Create Timetable">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={daysOfWeek}
                renderInput={(params) => <TextField {...params} label="Day" required />}
                value={timetableData.day || null}
                onChange={(e, value) => handleChange({ target: { name: 'day', value } })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={subjects}
                getOptionLabel={(option) => option.SubjectName}
                renderInput={(params) => <TextField {...params} label="Subject" required />}
                value={subjects.find(subject => subject.SubjectName === timetableData.subject) || null}
                onChange={handleSubjectChange}
                isOptionEqualToValue={(option, value) => option.ID === value.ID}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={professors}
                getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                renderInput={(params) => <TextField {...params} label="Professor" required />}
                value={professors.find(prof => `${prof.FirstName} ${prof.LastName}` === timetableData.professor) || null}
                onChange={handleProfessorChange}
                isOptionEqualToValue={(option, value) => option.ProfessorID === value.ProfessorID}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Start Time"
                name="startTime"
                type="time"
                value={timetableData.startTime}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="End Time"
                name="endTime"
                type="time"
                value={timetableData.endTime}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary">
              {timetableId ? 'Update Timetable' : 'Create Timetable'}
            </Button>
          </Box>
        </form>
      </SimpleCard>
    </Box>
  );
}

export default CreateTimetable;


