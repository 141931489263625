import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import SimpleMenu from './Simplemenu'; // Assuming SimpleMenu is shared
import { LuFileEdit } from "react-icons/lu";
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));
const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));


const PrefixRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

function PrefixFirstPage() {
  const navigate = useNavigate();
  const [prefixes, setPrefixes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [empID, setEmpID] = useState(null);
  useEffect(() => {
    // Retrieve EmpID from session storage
    const storedEmpID = sessionStorage.getItem('ID');
    if (storedEmpID) {
      setEmpID(storedEmpID);
      fetchPrefixes(storedEmpID); // Fetch departments using EmpID
    }
  }, []);

  const  fetchPrefixes = async (empID) => {
    if (!empID) {
      console.error('EmpID is not set in session storage.');
      return;
    }

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/prefixes/${empID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch prefixs');
      }
      const data = await response.json();
      console.log('Fetched prefixs:', data);
      setPrefixes(data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching prefixs:', error);
    }
  };

  // Fetch the prefix details by ID
  const fetchPrefixById = async (id) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/prefixes/${id}`);
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
        throw new Error('Failed to fetch prefix');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching prefix by ID:', error);
    }
  };


  const handleCreateClick = () => {
    navigate('/createPrefix');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = async (prefix) => {
    const fetchedPrefix = await fetchPrefixById(prefix.ID); // Fetch the prefix by ID
    if (fetchedPrefix) {
      navigate('/createPrefix', { state: { prefix: fetchedPrefix } });
    }
  };

  // Handle status toggle
  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0
    console.log('Toggling status for ID:', id, 'New Status:', newStatus); // Debugging log

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/prefixes/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }), // Update the status
      });

      if (response.ok) {
        console.log('Update successful for prefix ID:', id, 'New Status:', newStatus);
        setPrefixes((prevPrefixes) =>
          prevPrefixes.map((prefix) =>
            prefix.ID === id ? { ...prefix, Status: newStatus } : prefix
          )
        );
      } else {
        console.error('Failed to update prefix status');
      }
    } catch (error) {
      console.error('Error updating prefix status:', error);
    }
  };

  // Delete prefix by ID
  const handleDeleteClick = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this prefix?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/prefixes/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setPrefixes(prefixes.filter(prefix => prefix.ID !== id)); // Update the UI
        console.log('Prefix deleted successfully');
      } else {
        console.error('Failed to delete prefix');
      }
    } catch (error) {
      console.error('Error deleting prefix:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Prefix", path: "/createPrefix" }, { name: "Prefix List" }]} />
      </Box>
      <Box>
        {/*  <h2>Prefix Page</h2> */}
      </Box>

      <PrefixRow>
        <SimpleMenu />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Prefix
        </Button>
      </PrefixRow>
      <br />

      <SimpleCard title="Prefix List">
      <ScrollableTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">Prefix Name</TableCell>
              {/* <TableCell align="center">Employee ID</TableCell>
              <TableCell align="center">Designation</TableCell> */}
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prefixes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((prefix) => (
                <TableRow key={prefix.ID}> {/* Assuming 'ID' is a unique identifier */}
                  <TableCell align="left">{prefix.PrefixName}</TableCell>
                  {/* <TableCell align="center">{prefix.EmpID}</TableCell>
                  <TableCell align="center">{prefix.Designation}</TableCell> */}
                  <TableCell align="center">
                    {prefix.Status === true || prefix.Status === 1 ? (
                        <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon
                      ) : (
                        <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon
                      )}
                    </TableCell>
                  <TableCell align="center">{new Date(prefix.CreateDate).toLocaleDateString()}</TableCell>
                  <TableCell align="center">
                    <IconButton color="primary" onClick={() => handleEditClick(prefix)}>
                    <LuFileEdit style={{ fontSize: '30px' }} />
                    </IconButton>
                    {/* <IconButton color="secondary" onClick={() => handleDeleteClick(prefix.ID)}>
                      <Icon>delete</Icon>
                    </IconButton> */}
                    <Switch
                       checked={prefix.Status === true || prefix.Status === 1}
                          onChange={() => handleStatusChange(prefix.ID, prefix.Status)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
</ScrollableTableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={prefixes.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default PrefixFirstPage;
