
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LuFileEdit } from "react-icons/lu";
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  // Ensure the table doesn't shrink too much on small screens
  '& table': {
    minWidth: 600, // You can adjust this value based on your design needs
  },
}));

const DegreeRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2), // Space above the row
}));

function DegreeFirstPage() {
  const navigate = useNavigate();
  const [degrees, setDegrees] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [empID, setEmpID] = useState(null); // State for EmpID

  const fetchDegrees = async (empID) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/degree/${empID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch degrees');
      }
      const data = await response.json();
      
      // Convert boolean Status to 1 or 0
      const updatedData = data.map(degree => ({
        ...degree,
        Status: degree.Status ? 1 : 0,
      }));
      
      console.log('Fetched degrees:', updatedData);
      setDegrees(updatedData);
    } catch (error) {
      console.error('Error fetching degrees:', error);
    }
  };


  useEffect(() => {
    // Retrieve EmpID from session storage
    const storedEmpID = sessionStorage.getItem('ID');
    if (storedEmpID) {
      setEmpID(storedEmpID);
      fetchDegrees(storedEmpID); // Fetch degrees using EmpID
    }
  }, []);
  // Fetch the degree details by ID
  const fetchDegreeById = async (id) => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/degrees/${id}`);
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
        throw new Error('Failed to fetch degree');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching degree by ID:', error);
    }
  };

  useEffect(() => {
    fetchDegrees(); // Fetch degrees when the component mounts
  }, []);

  const handleCreateClick = () => {
    navigate('/createdegree');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = async (degree) => {
    const fetchedDegree = await fetchDegreeById(degree.ID); // Fetch the degree by ID
    if (fetchedDegree) {
      console.log('Fetched Degree Data:', fetchedDegree); // Debugging log to see fetched data
      navigate('/createDegree', { state: { degree: fetchedDegree } }); // Pass fetched data
    } else {
      console.error('No degree data found for ID:', degree.ID); // Log if no data is found
    }
};


  // Handle status toggle
  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0
    console.log('Toggling status for ID:', id, 'New Status:', newStatus); // Debugging log

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/degrees/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }), // Update the status
      });

      if (response.ok) {
        console.log('Update successful for degree ID:', id, 'New Status:', newStatus);
        setDegrees((prevDegrees) =>
          prevDegrees.map((deg) =>
            deg.ID === id ? { ...deg, Status: newStatus } : deg
          )
        );
      } else {
        console.error('Failed to update degree status');
      }
    } catch (error) {
      console.error('Error updating degree status:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Degree" }]} />
      </Box>
      <Box>
        {/* <h2>Degree Page</h2> */}
      </Box>

      <DegreeRow>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Degree
        </Button>
      </DegreeRow>
      <br />

      <SimpleCard title="Degree List">
      <ScrollableTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">Degree Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created Date</TableCell>
              {/* <TableCell align="center">Employee ID</TableCell>
              <TableCell align="center">Description</TableCell> */}
              <TableCell align="center">Actions</TableCell> {/* Actions column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {degrees
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((degree) => (
                <TableRow key={degree.ID}>
                  <TableCell align="left">{degree.DegreeName}</TableCell>
                  <TableCell align="center">{degree.Description}</TableCell>
                  <TableCell align="center">
                    {degree.Status === 1 ? (
                      <Icon sx={{ color: 'green' }}>check_circle</Icon> // Active icon
                    ) : (
                      <Icon sx={{ color: 'red' }}>cancel</Icon> // Inactive icon
                    )}
                  </TableCell>
                  <TableCell align="center">{new Date(degree.CreateDate).toLocaleDateString()}</TableCell>
                  {/* <TableCell align="center">{degree.EmpID}</TableCell>
                  <TableCell align="center">{degree.Description}</TableCell> */}
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <IconButton color="primary" onClick={() => handleEditClick(degree)}>
                      <LuFileEdit style={{ fontSize: '30px' }} />
                      </IconButton>
                      <Switch
                        checked={degree.Status === 1} // Active if Status is 1
                        onChange={() => handleStatusChange(degree.ID, degree.Status)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
</ScrollableTableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={degrees.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default DegreeFirstPage;
