import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  styled,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const CreateSemester = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const semesterToEdit = location.state?.semester;

  const [id, setId] = useState(null);
  const [createBy, setCreateBy] = useState(null);
  const [state, setState] = useState({
    semesterName: semesterToEdit ? semesterToEdit.SemesterName : '',
    description: semesterToEdit ? semesterToEdit.Description : '',
    status: semesterToEdit ? semesterToEdit.Status : false,
    createdDate: semesterToEdit ? new Date(semesterToEdit.CreatedDate) : new Date(),
    termsAgreed: false,
    year: semesterToEdit ? semesterToEdit.YearID : '', // Initialize with YearID
    yearName: semesterToEdit ? semesterToEdit.YearName : '', // Initialize with YearName
  });

  const [years, setYears] = useState([]); // State to hold years data

  useEffect(() => {
    const storedId = sessionStorage.getItem("ID");
    if (storedId) {
      setId(storedId);
      fetchYears(storedId);
    }

    const storedCreateBy = sessionStorage.getItem("Createby");
    if (storedCreateBy) {
      setCreateBy(storedCreateBy);
    }
  }, []);

  const fetchYears = async (empID) => {
    if (!empID) return;

    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/yearss/${empID}`);
      if (!response.ok) throw new Error('Failed to fetch years');
      const data = await response.json();

      // Update year options
      setYears(data.map(year => ({ ID: year.ID, YearName: year.YearName })));

      // If editing, find the year based on YearID
      if (semesterToEdit) {
        const selectedYear = data.find(year => year.ID === semesterToEdit.YearID);
        if (selectedYear) {
          setState(prevState => ({
            ...prevState,
            year: selectedYear.ID, // Set the year ID
            yearName: selectedYear.YearName // Set the year name
          }));
        }
      }
    } catch (error) {
      console.error('Error fetching years:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newSemester = {
      semesterName: state.semesterName,
      description: state.description,
      status: state.status,
      createdDate: new Date().toISOString(),
      empId: id,
      yearID: state.year, // Include the selected year ID
      yearName: state.yearName, // Include the selected year name
      updateDate: new Date().toISOString(),
    };

    try {
      const response = semesterToEdit
        ? await fetch(`https://attendance.api.lissomtech.in/api/semesters/${semesterToEdit.ID}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newSemester),
          })
        : await fetch('https://attendance.api.lissomtech.in/api/semesters', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newSemester),
          });

      if (response.ok) {
        console.log('Semester saved successfully');
        navigate('/semester');
      } else {
        console.error('Failed to save semester');
      }
    } catch (error) {
      console.error('Error saving semester:', error);
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    // Update the year in state based on selection
    setState(prevState => ({
      ...prevState,
      year: newValue ? newValue.ID : '',
      yearName: newValue ? newValue.YearName : '' // Save the year name
    }));
  };

  return (
    <Box margin="30px">
      <SimpleCard title={semesterToEdit ? "Edit Semester" : "Create Semester"}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                type="text"
                name="semesterName"
                value={state.semesterName || ""}
                onChange={(event) => setState({ ...state, semesterName: event.target.value })}
                label="Semester Name"
                required
                fullWidth
              />

              <TextField
                type="text"
                name="description"
                value={state.description || ""}
                onChange={(event) => setState({ ...state, description: event.target.value })}
                label="Semester Description"
                required
                fullWidth
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.status}
                    onChange={(event) => setState({ ...state, status: event.target.checked })}
                  />
                }
                label="Status (Active/Inactive)"
              />

              {/* Year Selection with Autocomplete */}
              <Autocomplete
                options={years} // Use the year objects directly
                getOptionLabel={(option) => option.YearName} // Display YearName
                value={years.find(year => year.ID === state.year) || null} // Match selected year
                onChange={handleAutocompleteChange} // Handle selection
                renderInput={(params) => (
                  <TextField {...params} label="Year" variant="outlined" fullWidth />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={state.termsAgreed}
                    onChange={(event) => setState({ ...state, termsAgreed: event.target.checked })}
                  />
                }
                label="I have read and agree to the terms of service."
              /> */}
            </Grid>
          </Grid>

          <Button color="primary" variant="contained" type="submit">
            {semesterToEdit ? "Update Semester" : "Create Semester"}
          </Button>
        </ValidatorForm>
      </SimpleCard>
    </Box>
  );
};

export default CreateSemester;
