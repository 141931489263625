

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LuFileEdit } from "react-icons/lu";
import {
  Box,
  Button,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";

// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  '& table': {
    minWidth: 600,
  },
}));

const DeviceRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(2),
}));

function DeviceFirstPage() {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch device data from the Tbl_ClassroomDevices API
  const fetchDevices = async () => {
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/classroom-devices`);
      if (!response.ok) {
        throw new Error('Failed to fetch devices');
      }
      const data = await response.json();
      console.log('Fetched devices:', data);
  
      // Access the devices from the recordset property
      if (Array.isArray(data.recordset)) {
        setDevices(data.recordset); // Set devices to the array from recordset
      } else {
        console.error('Expected an array in recordset but got:', data.recordset);
        setDevices([]); // Reset to an empty array if data is not an array
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
      setDevices([]); // Reset to an empty array on error
    }
  };
  

  useEffect(() => {
    fetchDevices(); // Fetch devices when the component mounts
  }, []);

  const handleCreateClick = () => {
    navigate('/createdevice');
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditClick = (device) => {
    navigate('/createdevice', { state: { deviceId: device.id } });
  };
  
  // Handle status toggle
  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      const response = await fetch(`https://attendance.api.lissomtech.in/api/classroom-devices/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Status: newStatus }),
      });
      if (response.ok) {
        setDevices((prevDevices) =>
          prevDevices.map((device) =>
            device.ID === id ? { ...device, Status: newStatus } : device
          )
        );
      } else {
        console.error('Failed to update device status');
      }
    } catch (error) {
      console.error('Error updating device status:', error);
    }
  };

  return (
    <Box margin="30px">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Device" }]} />
      </Box>

      <DeviceRow>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
          sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <Icon sx={{ marginRight: 1 }}>add</Icon>
          Create Device
        </Button>
      </DeviceRow>
      <br />

      <SimpleCard title="Device List">
        <ScrollableTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Device Number</TableCell>
                <TableCell align="center">Classroom Name</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {devices
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((device) => (
      <TableRow key={device.id}>
        <TableCell align="left">{device.device_id}</TableCell>
        <TableCell align="center">{device.ClassRoom}</TableCell>
        <TableCell align="center">
          {device.Status === true ? (
            <Icon sx={{ color: 'green' }}>check_circle</Icon>
          ) : (
            <Icon sx={{ color: 'red' }}>cancel</Icon>
          )}
        </TableCell>
        <TableCell align="center">
          {new Date(device.CreateDate).toLocaleDateString()}
        </TableCell>
        <TableCell align="center">
          <IconButton color="primary" onClick={() => handleEditClick(device)}>
            <LuFileEdit style={{ fontSize: '30px' }} />
          </IconButton>
          <Switch
            checked={device.Status === true}
            onChange={() => handleStatusChange(device.id, device.Status)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
      </TableRow>
    ))}
</TableBody>

          </StyledTable>
        </ScrollableTableContainer>

        <TablePagination
          sx={{ px: 2 }}
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          count={devices.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </SimpleCard>
    </Box>
  );
}

export default DeviceFirstPage;
